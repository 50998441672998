import { Cart, LineItem } from "../../../types";

export const getTotalItemPrice = (cartItem?: LineItem): number => {
  if (!cartItem) return 0;

  return (
    cartItem.price +
    cartItem.modifier_items
      .map((modfier) =>
        modfier.quantity ? modfier.quantity * modfier.price : 0,
      )
      .reduce((sum, price) => sum + price, 0)
  );
};

export const getCartSubtotal = (cart: Cart): number => {
  return cart.line_items
    .map((item) => getTotalItemPrice(item) * item.quantity)
    .reduce((sum, price) => sum + price, 0);
};
