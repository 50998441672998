import { useQuery, gql } from "@apollo/client";

export const LOCATION_TAGS_QUERY = gql`
  query LocationTags($location_slug: String) {
    location_tags {
      id
      name
      slug
      is_active
      is_collection
      collection_name
      ordinal
      menu_items(location_slug: $location_slug) {
        id
        count
        name
        brand_name
        description
        price
        is_active
        brand_slug
      }
    }
  }
`;

export const useLocationTags = (locationSlug: string) => {
  const { data, error, loading, refetch } = useQuery(LOCATION_TAGS_QUERY, {
    skip: !locationSlug,
    variables: { location_slug: locationSlug },
    fetchPolicy: "cache-and-network",
  });
  return { data, error, loading, refetch };
};
