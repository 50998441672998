import { useQuery, gql } from "@apollo/client";

export const BANNERS_CONTENT_QUERY = gql`
  query KioskActiveBanners($locationSlug: String) {
    kiosk_active_banners(locationSlug: $locationSlug) {
      id
      background_color
      mobile
      desktop
      brands {
        id
        slug
        name
      }
    }
  }
`;

export const useBanners = (locationSlug?: string) => {
  const { data, error, loading, refetch } = useQuery(BANNERS_CONTENT_QUERY, {
    pollInterval: 60 * 60 * 1000, // 1 hour
    variables: {
      locationSlug,
    },
  });

  return { data, error, loading, refetch };
};
