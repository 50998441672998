import { gql, useQuery } from "@apollo/client";

const KIOSK_MENU_QUERY = gql`
  query KioskMenuQuery(
    $location_slug: String
    $filter_active_modifiers: Boolean
  ) {
    public_stores(location_slug: $location_slug) {
      id
      is_within_hours
      is_open
      ordinal
      brand {
        id
        name
        slug
      }
      store_hours {
        day_of_week
        hours {
          start_day
          end_day
          start_time
          end_time
        }
      }
      location {
        id
        name
        slug
      }
      most_popular {
        id
        count
      }
      menus {
        id
        menu_categories {
          id
          name
          ordinal
          menu_items {
            id
            name
            description
            brand_name
            brand_slug
            image_url
            blurhash
            price
            is_active
            modifier_lists {
              id
              name
              min
              max
              is_single_select
              modifier_items(
                filter_active_modifiers: $filter_active_modifiers
              ) {
                id
                name
                is_active
                price
              }
            }
          }
        }
      }
    }
  }
`;

const KIOSK_MANAGER_MENU_QUERY = gql`
  query KioskManagerMenuQuery(
    $location_slug: String
    $filter_active_modifiers: Boolean
  ) {
    private_stores(location_slug: $location_slug) {
      id
      is_within_hours
      is_open
      ordinal
      brand {
        id
        name
        slug
      }
      store_hours {
        day_of_week
        hours {
          start_day
          end_day
          start_time
          end_time
        }
      }
      location {
        id
        name
        slug
      }
      most_popular {
        id
        count
      }
      menus {
        id
        menu_categories {
          id
          name
          ordinal
          menu_items {
            id
            name
            description
            brand_name
            brand_slug
            image_url
            blurhash
            price
            is_active
            tags {
              id
              name
              is_active
              slug
            }
            modifier_lists {
              id
              name
              min
              max
              is_single_select
              modifier_items(
                filter_active_modifiers: $filter_active_modifiers
              ) {
                id
                name
                price
              }
            }
          }
        }
      }
    }
  }
`;

export const useMenu = (locationSlug: string) => {
  const { data, error, loading, refetch } = useQuery(KIOSK_MENU_QUERY, {
    variables: { location_slug: locationSlug, filter_active_modifiers: true },
    pollInterval: 1 * 60 * 1000,
  });
  return { data, error, loading, refetch };
};

export const useManagerMenu = (locationSlug: string, isManager: boolean) => {
  const { data, error, loading, refetch } = useQuery(KIOSK_MANAGER_MENU_QUERY, {
    skip: !isManager,
    variables: { location_slug: locationSlug, filter_active_modifiers: false },
    pollInterval: 1 * 60 * 1000,
  });
  return { data, error, loading, refetch };
};
