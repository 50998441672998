import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Brands, Cart, ItemsMap } from "../../types";
import {
  addMenuItem,
  removeMenuItem,
  removeUnaddedItems,
} from "../../utils/cart";
import { useQueryParams } from "../Menu/MenuController";
import { MenuItem } from "./MenuItem";

interface Params {
  itemId: string;
}

type MenuItemControllerProps = {
  cart: Cart;
  itemsMap: ItemsMap;
  brands: Brands;
  collections: any[];
  onCartChange: (cart: Cart) => void;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  clearCart: () => void;
};

export const MenuItemController = ({
  cart,
  itemsMap,
  brands,
  collections,
  onCartChange,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  clearCart,
}: MenuItemControllerProps) => {
  const [externalId, setExternalId] = useState("");
  const { itemId } = useParams<Params>();
  const queryParams = useQueryParams();
  const previousExternalId = useRef<null | string>(null);
  const cartRef = useRef(cart);
  const history = useHistory();
  const currentItem = itemsMap[parseInt(itemId)];

  useEffect(() => {
    if (previousExternalId.current) {
      cartRef.current = removeMenuItem(
        cartRef.current,
        previousExternalId.current,
      );
    }

    if (currentItem) {
      const { externalId, updatedCart } = addMenuItem(cartRef.current, {
        ...currentItem,
        quantity: queryParams.get("quantity") ?? 1,
        is_checkout_carousel_item:
          queryParams.get("is_checkout_carousel_item") === "true",
      });
      cartRef.current = updatedCart;
      previousExternalId.current = externalId;
      onCartChange(updatedCart);
      setExternalId(externalId);
    }

    return () => {
      const updatedCart = removeUnaddedItems(cartRef.current);
      onCartChange(updatedCart);
    };
  }, [itemId]);

  useEffect(() => {
    cartRef.current = cart;

    if (
      queryParams.get("quantity") ||
      queryParams.get("is_checkout_carousel_item")
    ) {
      history.replace(history.location.pathname);
    }
  }, [cart]);

  if (!currentItem) {
    return null;
  }

  return (
    <MenuItem
      viewType="menu-item"
      externalId={externalId}
      cart={cart}
      item={currentItem}
      brands={brands}
      collections={collections}
      onCartChange={onCartChange}
      onMenuItemRemove={onMenuItemRemove}
      onMenuItemEdit={onMenuItemEdit}
      onMenuItemAdd={onMenuItemAdd}
      onMenuItemUpdate={onMenuItemUpdate}
      clearCart={clearCart}
    />
  );
};
