import { Cart } from "../../types";
import { Email } from "./Email";

import { updateEmail } from "../../utils/cart";
import { useAttachReceiptEmail } from "./hooks/useAttachReceiptEmail";

type EmailControllerProps = {
  cart: Cart;
  onCartChange: (cart: Cart) => void;
  onFinish: () => void;
};

export const EmailController = ({
  cart,
  onCartChange,
  onFinish,
}: EmailControllerProps) => {
  const { attachReceiptEmail } = useAttachReceiptEmail();

  const onFinishStep = async (email: string) => {
    await attachReceiptEmail({
      variables: {
        payment_intent_id: cart.stripe_payment_intent_id,
        receipt_email: email,
      },
    });

    const updatedCart = updateEmail(cart, email);
    onCartChange(updatedCart);

    onFinish();
  };

  return <Email cart={cart} onFinishStep={onFinishStep} />;
};
