import { useAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTrackWithFlags } from "../hooks/useTrackWithFlags";
import { TrackState } from "../index";
import User from "./User";

interface AppProps {
  setTrackingHandler: Dispatch<SetStateAction<TrackState>>;
}

const App = ({ setTrackingHandler }: AppProps) => {
  const { user, isAuthenticated } = useAuth();
  const { track } = useTrackWithFlags();

  useEffect(() => {
    setTrackingHandler({ track });

    if (user?.email) {
      Sentry.setUser({
        id: user.user_id,
        email: user.email,
        username: user.name,
      });
    }
  }, [user, isAuthenticated]);

  return <User />;
};

export default App;
