import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Cart, LineItem } from "../../types";
import { editMenuItem } from "../../utils/cart";
import { MenuItem } from "./MenuItem";

interface Params {
  itemId: string;
  externalId: string;
}

type EditMenuItemControllerProps = {
  cart: Cart;
  itemsMap: any;
  brands: any;
  collections: any[];
  onCartChange: (cart: Cart) => void;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  clearCart: () => void;
};

export const EditMenuItemController = ({
  cart,
  itemsMap,
  brands,
  collections,
  onCartChange,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  clearCart,
}: EditMenuItemControllerProps) => {
  const { itemId, externalId } = useParams<Params>();

  const currentItem = itemsMap[itemId];

  useEffect(() => {
    const [existingLineItem] = cart.line_items.filter((lineItem: LineItem) => {
      return lineItem.external_id === externalId;
    });

    if (existingLineItem) {
      const updatedCart = editMenuItem(cart, existingLineItem);
      onCartChange(updatedCart);
    }
  }, []);

  if (!currentItem) {
    return null;
  }

  return (
    <MenuItem
      viewType="menu-item/edit"
      externalId={externalId}
      cart={cart}
      item={currentItem}
      brands={brands}
      collections={collections}
      onCartChange={onCartChange}
      onMenuItemRemove={onMenuItemRemove}
      onMenuItemEdit={onMenuItemEdit}
      onMenuItemAdd={onMenuItemAdd}
      onMenuItemUpdate={onMenuItemUpdate}
      clearCart={clearCart}
    />
  );
};
