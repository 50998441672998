import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { SWIPE_THRESHOLD } from "../../../constants";
import MenuItemPage from "./MenuItemPage";
import "./swiper.css";

SwiperCore.use([Navigation]);

type MenuItemSelectorProps = {
  brands: any;
  currentMenu: any;
  menuItemPages: Array<any>;
  currentCollection: any;
  pageIndex: any;
  onMenuItemClick: (currentItem: any) => void;
  onMenuItemPageClick: (pageIndex: number) => void;
  isManager: boolean;
};

export const MenuItemSelector = ({
  brands,
  currentMenu,
  menuItemPages,
  currentCollection,
  pageIndex,
  onMenuItemClick,
  onMenuItemPageClick,
  isManager,
}: MenuItemSelectorProps) => {
  return (
    <div className="h-full w-full">
      <Swiper
        initialSlide={pageIndex || 0}
        key={`${currentMenu?.brand?.slug}${currentCollection}`}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        slidesPerView="auto"
        threshold={SWIPE_THRESHOLD}
        onSlideChangeTransitionEnd={(swiper) =>
          onMenuItemPageClick(swiper?.activeIndex)
        }
      >
        {menuItemPages.map((menuItemPage: any, pageIndex: number) => (
          <SwiperSlide key={pageIndex}>
            <MenuItemPage
              brands={brands}
              currentMenu={currentMenu}
              menuItemPage={menuItemPage}
              onMenuItemClick={onMenuItemClick}
              isManager={isManager}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
