import { v4 as uuidv4 } from "uuid";

import { Cart, DiningOption, LineItem, MenuItem } from "../types";

export const getLocalStorageCart = () => {
  const cart: Cart = JSON.parse(window.localStorage.getItem("cart") || "{}");
  return cart;
};

export const setLocalStorageCart = (cart: Cart) => {
  window.localStorage.setItem("cart", JSON.stringify(cart));
};

export const getEmptyCart = (location: string) => {
  const cart: Cart = {
    id: uuidv4(),
    type: "REGULAR",
    provider: "kiosk",
    location,
    line_items: [],
    dining_option: DiningOption.TAKE_OUT,
    name: "",
    email: "",
    phone_number: "",
    tip_rate: 1500,
    tip_amount: 0,
    tax_rate: 0,
    tax_amount: 0,
    subtotal: 0,
    total: 0,
    points_earned: 0,
    stripe_payment_intent_id: "",
  };

  return cart;
};

export const addMenuItem = (cart: Cart, menuItem: any) => {
  const externalId = uuidv4();
  const updatedCart = { ...cart };

  updatedCart.line_items.push({
    id: menuItem.id,
    external_id: externalId,
    quantity: menuItem.quantity || 1,
    name: menuItem.name,
    description: menuItem.description,
    price: menuItem.price,
    brand_name: menuItem.brand_name,
    modifier_items: [],
    is_added: false,
    is_checkout_carousel_item: !!menuItem.is_checkout_carousel_item,
  });

  return { externalId, updatedCart };
};

export const editMenuItem = (cart: Cart, existingLineItem: any) => {
  const updatedCart = { ...cart };

  updatedCart.stashed_line_item = { ...existingLineItem };

  return updatedCart;
};

export const addMenuItemToCart = (cart: Cart, externalId?: string) => {
  const updatedCart = { ...cart };

  for (let i = 0; i < cart.line_items.length; i += 1) {
    const currentLineItem = cart.line_items[i];

    if (currentLineItem.external_id === externalId) {
      currentLineItem.is_added = true;
      break;
    }
  }

  return updatedCart;
};

export const updateMenuItemInCart = (cart: Cart, externalId?: string) => {
  const updatedCart = { ...cart };
  updatedCart.stashed_line_item = undefined;
  return updatedCart;
};

export const removeMenuItem = (cart: Cart, externalId: any) => {
  const updatedCart = { ...cart };

  const newLineItems = updatedCart.line_items.filter((lineItem: any) => {
    return lineItem.external_id !== externalId;
  });

  updatedCart.line_items = newLineItems;

  return updatedCart;
};

export const updateMenuItemQuantity = (
  cart: Cart,
  menuItemExternalId: any,
  quantity: number,
) => {
  const updatedCart = { ...cart };

  for (let i = 0; i < updatedCart.line_items.length; i += 1) {
    const currentLineItem = updatedCart.line_items[i];

    if (currentLineItem.external_id === menuItemExternalId) {
      currentLineItem.quantity = quantity;
      break;
    }
  }

  return updatedCart;
};

export const removeModifierItem = (
  cart: Cart,
  menuItemExternalId: any,
  modifierItemExternalId: any,
) => {
  const updatedCart = { ...cart };

  for (let i = 0; i < updatedCart.line_items.length; i += 1) {
    const currentLineItem = updatedCart.line_items[i];

    if (currentLineItem.external_id === menuItemExternalId) {
      const newModifierItems = currentLineItem.modifier_items.filter(
        (item: any) => {
          return item.external_id !== modifierItemExternalId;
        },
      );

      currentLineItem.modifier_items = newModifierItems;
      break;
    }
  }

  return updatedCart;
};

export const updateModifierItemQuantity = (
  cart: Cart,
  modifierItemExternalId: any,
  quantity: number,
) => {
  const updatedCart = { ...cart };

  let isModifierItemUpdated = false;

  for (let i = 0; i < updatedCart.line_items.length; i += 1) {
    if (isModifierItemUpdated) {
      break;
    }

    const currentLineItem = updatedCart.line_items[i];

    for (let j = 0; j < currentLineItem.modifier_items.length; j += 1) {
      const currentModifierItem = currentLineItem.modifier_items[j];

      if (currentModifierItem.external_id === modifierItemExternalId) {
        currentModifierItem.quantity = quantity;
        isModifierItemUpdated = true;
        break;
      }
    }
  }

  return updatedCart;
};

export const updateName = (cart: Cart, name: string) => {
  const updatedCart = { ...cart };

  updatedCart.name = name;

  return updatedCart;
};

export const updateDiningOption = (cart: Cart, diningOption: DiningOption) => {
  const updatedCart = { ...cart };

  updatedCart.dining_option = diningOption;

  return updatedCart;
};

export const updatePhoneNumber = (cart: Cart, phoneNumber: string | null) => {
  const updatedCart = { ...cart };

  updatedCart.phone_number = phoneNumber;

  return updatedCart;
};

export const updateEmail = (cart: Cart, email: string) => {
  const updatedCart = { ...cart };

  updatedCart.email = email;

  return updatedCart;
};

export const updateTipRate = (cart: Cart, tipRate: number) => {
  const updatedCart = { ...cart };

  updatedCart.tip_rate = tipRate;

  return updatedCart;
};

export const updateTaxRate = (cart: Cart, taxRate: number) => {
  const updatedCart = { ...cart };

  updatedCart.tax_rate = taxRate;

  return updatedCart;
};

export const removeUnaddedItems = (cart: Cart) => {
  const updatedCart = { ...cart };
  const newLineItems = updatedCart.line_items.filter(
    (lineItem) => lineItem.is_added,
  );

  const stashedLineItem = cart.stashed_line_item;

  if (stashedLineItem) {
    for (let i = 0; i < newLineItems.length; i += 1) {
      const newLineItem = newLineItems[i];

      if (newLineItem.external_id === stashedLineItem.external_id) {
        newLineItems[i] = stashedLineItem;
        break;
      }
    }
  }

  updatedCart.line_items = newLineItems;

  return updatedCart;
};

/**
 * @param cart
 * @param lineItem
 * @returns a common set of properties for adding, updating, and removing from cart
 */
export const getCartActionProperties = (
  cart: Cart,
  item: LineItem | MenuItem,
) => {
  return {
    product_id: item.id,
    sku: item.id,
    name: item.name,
    price: item.price / 100,
    brand: item.brand_name,
    location: cart.location,
    cart_id: cart.id,
    currency: "USD",
  };
};
