import { useEffect, useRef, useState } from "react";
import { LineItem } from "../../../types";
import { ActiveLineItem } from "./ActiveLineItem";

type ActiveLineItemsProps = {
  items: any;
  externalId: any;
  onItemClick: any;
};

export const ActiveLineItems = ({
  items,
  externalId,
  onItemClick,
}: ActiveLineItemsProps) => {
  const [maxHeight, setMaxHeight] = useState(undefined);

  const itemsRef: any = useRef(null);
  const endRef: any = useRef(null);

  const lineItemsStyle: any = {};

  useEffect(() => {
    const newHeight = itemsRef?.current?.clientHeight;
    if (newHeight !== undefined) {
      setMaxHeight(newHeight);
    }
  }, [itemsRef?.current?.clientHeight]);

  if (maxHeight !== undefined) {
    lineItemsStyle.maxHeight = maxHeight;
    lineItemsStyle.overflowY = "scroll";
  }

  const scrollToBottom = () => {
    endRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [maxHeight]);

  return (
    <ul className="flex-1 p-4" ref={itemsRef} style={lineItemsStyle}>
      {items.map((item: LineItem) => (
        <ActiveLineItem
          key={item.external_id}
          isActive={item.external_id === externalId}
          item={item}
          onClick={onItemClick}
        />
      ))}
      <div ref={endRef} />
    </ul>
  );
};
