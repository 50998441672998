import { gql, useQuery } from "@apollo/client";

export const KIOSK_LOYALTY_ACCOUNT_QUERY = gql`
  query KioskLoyaltyAccountQuery($phone_number: String) {
    loyalty_account(phone_number: $phone_number) {
      id
      credits
      points
    }
  }
`;

export const useLoyaltyAccount = (phone_number: string | null) => {
  const { data, error, loading, refetch } = useQuery(
    KIOSK_LOYALTY_ACCOUNT_QUERY,
    {
      variables: { phone_number },
    },
  );

  return { data, error, loading, refetch };
};
