import { renderFillers } from "../../../utils/renderFillers";
import { MENU_ITEM_PER_PAGE } from "../Menu";
import MenuItem from "./MenuItem";

type MenuItemPageProps = {
  brands: any;
  currentMenu: any;
  menuItemPage: any;
  onMenuItemClick: any;
  isManager: boolean;
};

const MenuItemPage = ({
  brands,
  currentMenu,
  menuItemPage,
  onMenuItemClick,
  isManager,
}: MenuItemPageProps) => {
  return (
    <ul className="grid grid-cols-2 gap-2 flex-1 px-4 auto-rows-fr h-full">
      {menuItemPage.map((currentItem: any) => {
        return (
          <MenuItem
            brands={brands}
            currentMenu={currentMenu}
            currentItem={currentItem}
            onMenuItemClick={onMenuItemClick}
            isManager={isManager}
          />
        );
      })}
      {renderFillers(menuItemPage.length, MENU_ITEM_PER_PAGE)}
    </ul>
  );
};

export default MenuItemPage;
