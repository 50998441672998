import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { Cart } from "../../types";
import { paginateItems } from "../../utils/paginateItems";
import { ActiveCart } from "../ActiveCart";
import CollectionsSelector from "./CollectionsSelector";
import { BrandSelector } from "./components/BrandSelector";
import { MenuItemPageSelector } from "./components/MenuItemPageSelector";
import { MenuItemSelector } from "./components/MenuItemSelector";
import { useQueryParams } from "./MenuController";
import { getMenuItemPages } from "./utils/getMenuItemPages";

type MenuProps = {
  cart: Cart;
  menus: any;
  brands: any;
  brandIndex: number;
  isManager: boolean;
  initialPageIndex: number;
  initialCollection: any;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  onCartChange: (cart: Cart) => void;
  clearCart: () => void;
};

// Display five brands per page in top bar
export const BRAND_PER_PAGE = 5;

// Display four menu items per page in main section
export const MENU_ITEM_PER_PAGE = 4;

export const Menu = ({
  cart,
  menus,
  brands,
  brandIndex,
  initialPageIndex,
  initialCollection,
  isManager,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  onCartChange,
  clearCart,
}: MenuProps) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  // Keep track of current brand by tracking current page and index within that page
  const [currentBrandIndex, setCurrentBrandIndex] = useState(brandIndex);
  const [currentBrandPageIndex, setCurrentBrandPageIndex] = useState(0);

  const initialBrandPages = paginateItems(menus, BRAND_PER_PAGE);
  const [brandPages, setBrandPages] = useState<any>(initialBrandPages);
  const [menuItemPages, setMenuItemPages] = useState<any>([[]]);

  // Keep track of what menu items to display by tracking current menu and page within that menu
  const [currentPageIndex, setCurrentPageIndex] = useState(initialPageIndex);
  const [currentMenu, setCurrentMenu] = useState<any>(undefined);

  const [currentCollection, setCurrentCollection] = useState(initialCollection);

  useEffect(() => {
    const newBrandPageIndex = Math.floor(brandIndex / BRAND_PER_PAGE);
    const newBrandIndex =
      newBrandPageIndex === 0
        ? brandIndex
        : brandIndex - newBrandPageIndex * BRAND_PER_PAGE;
    const newBrandPages = paginateItems(menus, BRAND_PER_PAGE);

    setCurrentBrandPageIndex(newBrandPageIndex);
    setCurrentBrandIndex(newBrandIndex);
    setCurrentPageIndex(initialPageIndex);
    setBrandPages(newBrandPages);
  }, [brandIndex, initialPageIndex, menus]);

  useEffect(() => {
    if (currentMenu) {
      const newMenuItemPages = getMenuItemPages(
        currentMenu,
        MENU_ITEM_PER_PAGE,
        currentCollection,
      );
      setMenuItemPages(newMenuItemPages);
      setCurrentPageIndex(0);
      history.push({
        pathname: window.location.pathname,
        search: `?page=0&collection=${currentCollection}`,
      });
    }
  }, [currentCollection]);

  useEffect(() => {
    const newCurrentMenu = brandPages[currentBrandPageIndex][currentBrandIndex];
    const newMenuItemPages = getMenuItemPages(
      newCurrentMenu,
      MENU_ITEM_PER_PAGE,
      currentCollection,
    );

    setCurrentMenu(newCurrentMenu);
    setMenuItemPages(newMenuItemPages);
  }, [brandPages]);

  useEffect(() => {
    const newCollection = queryParams.get("collection");
    if (newCollection && newCollection !== currentCollection) {
      onCollectionChange(newCollection);
    }
  }, [history.location.search]);

  if (!currentMenu) {
    return null;
  }

  // Change the route when brand is clicked
  const onBrandClick = (menuId: number) => {
    if (menuId === 0) {
      history.push({
        pathname: "/menu",
        search: `?page=0&collection=${currentCollection}`,
      });
    } else {
      history.push({
        pathname: `/menu/${menuId}`,
        search: `?page=0&collection=${currentCollection}`,
      });
    }
  };

  // Change the route when available menu item is clicked
  const onMenuItemClick = (currentItem: any) => {
    if (isManager) {
      history.push(`/menu-item/${currentItem.id}`);
      return null;
    }

    if (currentMenu.is_open && currentItem.is_active !== false) {
      history.push(`/menu-item/${currentItem.id}`);
    }
  };

  // Used by menu item swiper to change index after successful swipe
  const onMenuItemPageClick = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageIndex}&collection=${currentCollection}`,
    });
  };

  // When previous arrow is manually clicked
  const onMenuItemPagePrevious = (currentPageIndex: number) => {
    setCurrentPageIndex(currentPageIndex - 1);
    history.push({
      pathname: window.location.pathname,
      search: `?page=${currentPageIndex - 1}&collection=${currentCollection}`,
    });
  };

  // When next arrow is manually clicked
  const onMenuItemPageNext = (currentPageIndex: number) => {
    setCurrentPageIndex(currentPageIndex + 1);
    history.push({
      pathname: window.location.pathname,
      search: `?page=${currentPageIndex + 1}&collection=${currentCollection}`,
    });
  };

  // Used by brand swiper to trigger after a successful swipe
  const onSlideChange = (pageIndex: number, direction: string) => {
    let menuId;

    if (direction === "next") {
      menuId = brandPages[pageIndex][0].id;
    } else {
      menuId = brandPages[pageIndex][BRAND_PER_PAGE - 1].id;
    }

    if (menuId === 0) {
      history.push({
        pathname: "/menu",
        search: `?page=0&collection=${currentCollection}`,
      });
    } else {
      history.push({
        pathname: `/menu/${menuId}`,
        search: `?page=0&collection=${currentCollection}`,
      });
    }

    setCurrentPageIndex(0);
    setCurrentBrandPageIndex(pageIndex);
  };

  // Used to display empty page when there's no available items
  const noItemsAvailable = menuItemPages[0].length === 0;

  const onCollectionChange = (collection: string) => {
    setCurrentPageIndex(0);
    setCurrentCollection(collection);
    history.push({
      pathname: window.location.pathname,
      search: `?page=0&collection=${collection}`,
    });
  };

  const getCollectionName = () => {
    if (currentCollection === "popular") {
      return "Most Popular";
    }

    if (["kidsmenu", "kids", "kid"].includes(currentCollection)) {
      return "Kids Menu";
    }

    if (
      ["drinks", "drink", "beverage", "beverages"].includes(currentCollection)
    ) {
      return "Beverages";
    }

    if (currentCollection === "vegetarian") {
      return "Vegetarian";
    }
  };

  const collectionName = getCollectionName();
  const collectionLogo = brands[currentCollection]?.roundLogo?.url;

  return (
    <div className="bg-lfg-light-gray h-screen w-full flex flex-col fixed">
      <BrandSelector
        brands={brands}
        currentBrandIndex={currentBrandIndex}
        currentBrandPageIndex={currentBrandPageIndex}
        brandPages={brandPages}
        collectionName={collectionName}
        collectionLogo={collectionLogo}
        onBrandClick={onBrandClick}
        setCurrentBrandPageIndex={setCurrentBrandPageIndex}
        onSlideChange={onSlideChange}
        setCurrentBrandIndex={setCurrentBrandIndex}
      />
      <div className="flex flex-1">
        {noItemsAvailable ? (
          <div className="flex-1 flex flex-col w-9/12 justify-between mx-4 mb-4">
            <h1 className="text-center mt-32 text-3xl text-gray-700">
              No items available
            </h1>
            <img className="w-full" src="/sf.png" />
          </div>
        ) : (
          <div className="flex-1 flex flex-col w-9/12">
            {brandIndex === 0 && (
              <CollectionsSelector
                brands={brands}
                collections={menus[0]?.collections}
                currentCollection={currentCollection}
                onCollectionChange={onCollectionChange}
              />
            )}
            <MenuItemSelector
              pageIndex={initialPageIndex}
              currentCollection={currentCollection}
              brands={brands}
              currentMenu={currentMenu}
              menuItemPages={menuItemPages}
              onMenuItemClick={onMenuItemClick}
              onMenuItemPageClick={onMenuItemPageClick}
              isManager={isManager}
            />
            {menuItemPages.length > 1 ? (
              <MenuItemPageSelector
                currentPageIndex={currentPageIndex}
                menuItemPages={menuItemPages}
                onMenuItemPagePrevious={onMenuItemPagePrevious}
                onMenuItemPageNext={onMenuItemPageNext}
              />
            ) : (
              <div className="p-2" />
            )}
          </div>
        )}

        <UserContext.Consumer>
          {({ logout }) => (
            <ActiveCart
              menus={menus}
              brands={brands}
              viewType="menu"
              cart={cart}
              collections={menus[0]?.collections}
              onMenuItemRemove={onMenuItemRemove}
              onMenuItemEdit={onMenuItemEdit}
              onMenuItemAdd={onMenuItemAdd}
              onMenuItemUpdate={onMenuItemUpdate}
              onCartChange={onCartChange}
              clearCart={clearCart}
              logout={logout}
            />
          )}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
