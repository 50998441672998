import React from "react";
import ReactMarkdown from "react-markdown";

interface IBanner {
  notice: string;
  shortNotice: string;
  showLink: boolean;
  showIcon: boolean;
  backgroundColor?: string;
}

export const Banner = ({
  notice,
  shortNotice,
  showLink,
  showIcon,
  backgroundColor,
}: IBanner) => {
  return (
    <div
      className="relative border-lfg-primary bg-center bg-cover bg-orange"
      style={{
        backgroundImage: Boolean(backgroundColor && backgroundColor !== " ")
          ? "none"
          : `url("https://lfg-landing.s3.us-west-2.amazonaws.com/lfg-orange-banner.png")`,
        backgroundColor: Boolean(backgroundColor || backgroundColor === " ")
          ? backgroundColor
          : "unset",
      }}
    >
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="sm:pr-16 sm:text-center sm:px-16 flex justify-center">
          {showIcon && (
            <svg
              className="h-6 w-6 text-white inline mr-1 flex-shrink-0"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
              />
            </svg>
          )}
          <p className="font-lfg-medium text-white flex justify-center">
            <ReactMarkdown className="hidden md:inline">{notice}</ReactMarkdown>
            <ReactMarkdown className="inline md:hidden">
              {shortNotice}
            </ReactMarkdown>
          </p>
        </div>
      </div>
    </div>
  );
};
