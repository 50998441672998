import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart } from "../../types";
import { updateTipRate } from "../../utils/cart";
import { usdFormatter } from "../../utils/usdFormatter";
import { getPaymentInfo } from "../CreditCard/CreditCardController";
import { PrepTimeEstimate } from "../PrepTimeEstimate";

const tipRates = [
  {
    rate: 0,
    displayValue: "0%",
  },
  {
    rate: 1000,
    displayValue: "10%",
  },
  {
    rate: 1500,
    displayValue: "15%",
  },
  {
    rate: 2000,
    displayValue: "20%",
  },
  {
    rate: 2500,
    displayValue: "25%",
  },
];

type TipProps = {
  isManager: boolean;
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

export const Tip = ({ isManager, cart, onCartChange }: TipProps) => {
  const history = useHistory();
  const [tipRate, setTipRate] = useState(cart.tip_rate);
  const { track } = useTrackWithFlags();

  let currentTipRateIndex = 0;

  for (let i = 0; i < tipRates.length; i += 1) {
    if (tipRates[i].rate === tipRate) {
      currentTipRateIndex = i;
      break;
    }
  }

  const [currentIndex, setCurrentIndex] = useState(currentTipRateIndex);

  const onTipRateClick = (event: any, rate: number, index: number) => {
    setTipRate(rate);
    setCurrentIndex(index);
  };

  const onSubmit = () => {
    track({
      event: "Tip Step Completed",
      properties: { tipRate },
    });
    const updatedCart = updateTipRate(cart, tipRate);
    onCartChange(updatedCart);

    history.push("/credit-card");
  };

  const { subtotal } = getPaymentInfo(cart);

  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen">
      <PrepTimeEstimate />
      <main className="mx-auto w-5/12 px-4 mb-16 flex flex-col">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div
          className="text-center flex-1 flex flex-col"
          style={{ minHeight: 314 }}
        >
          <h1 className="text-5xl tracking-tight font-lfg-bold">
            <span>Tip</span>
          </h1>
          <div className="flex flex-col justify-between flex-1">
            <div className="mt-12">
              <ul className="flex items-center justify-center ml-2">
                {tipRates.map((tipRate: any, index: number) => (
                  <button
                    style={{ minWidth: 100 }}
                    className={`${
                      currentIndex === index
                        ? "bg-green-500 text-white font-lfg-semibold"
                        : ""
                    } border border-gray-300 bg-white py-4 mr-2`}
                    onClick={(event: any) =>
                      onTipRateClick(event, tipRate.rate, index)
                    }
                  >
                    <div className="text-3xl mb-1 font-lfg-book">
                      {tipRate.displayValue}
                    </div>
                    <div
                      className={`text-xl ${
                        currentIndex === index ? "text-white" : "text-gray-500"
                      }`}
                    >
                      {usdFormatter.format(
                        Math.round(subtotal * (tipRate.rate * 0.0001)) / 100,
                      )}
                    </div>
                  </button>
                ))}
              </ul>
            </div>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button
                  onClick={onSubmit}
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                >
                  Next
                </button>
              </div>
            </div>
            {isManager && (
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <button
                    onClick={() => history.push("/credit-card")}
                    className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-gray-700 bg-gray-300"
                  >
                    Skip
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
