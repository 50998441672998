import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { Tappable } from "../../../components/Tappable";
import { ModifierItem } from "../../../types";
import { usdFormatter } from "../../../utils/usdFormatter";

type ActiveModifierItemProps = {
  modifierListIndex: any;
  modifierItem: any;
  modifierList: any;
  modifierItemQuantity: number;
  modifierItemExternalId: string;
  onModifierItemClick: (
    modifierItem: ModifierItem,
    modifierListIndex: number,
  ) => void;
  onChangeQuantity: (
    modifierItemExternalId: string,
    modifierItemQuantity: number,
  ) => void;
};

export const ActiveModifierItem = ({
  modifierListIndex,
  modifierList,
  modifierItem,
  modifierItemExternalId,
  modifierItemQuantity,
  onModifierItemClick,
  onChangeQuantity,
}: ActiveModifierItemProps) => {
  const onButtonClick = () => {
    onModifierItemClick(modifierItem, modifierListIndex);
  };

  const onDecreaseQuantityClicked = (event: any) => {
    event.stopPropagation();
    onChangeQuantity(modifierItemExternalId, modifierItemQuantity - 1);
  };

  const onIncreaseQuantityClicked = (event: any) => {
    event.stopPropagation();
    onChangeQuantity(modifierItemExternalId, modifierItemQuantity + 1);
  };

  return (
    <li>
      <div role="button" className="w-full h-full">
        <div className="bg-gray-100 rounded flex border border-gray-200 h-full text-left shadow">
          <div className="flex items-center w-full">
            {modifierList.is_single_select && (
              <Tappable
                className="h-full flex items-center"
                onPress={onButtonClick}
              >
                {modifierItemQuantity > 0 && (
                  <div className="ml-4 px-4 flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-10 w-10 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </Tappable>
            )}
            {!modifierList.is_single_select && (
              <>
                {modifierItemQuantity > 0 && (
                  <div className="flex">
                    <div className="flex">
                      <button
                        onClick={onDecreaseQuantityClicked}
                        className="w-16 flex justify-center items-center"
                      >
                        <MinusIcon
                          className="h-8 w-8 text-green-600"
                          aria-hidden="true"
                        />
                      </button>
                      <div className="flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                        <p className="text-2xl text-green-600 font-lfg-semibold">
                          {modifierItemQuantity}
                        </p>
                      </div>
                      <button
                        onClick={onIncreaseQuantityClicked}
                        className="w-16 flex justify-center items-center"
                      >
                        <PlusIcon
                          className="h-8 w-10 text-green-600"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            <Tappable
              className="flex items-start flex-col px-4 h-full justify-center flex-1"
              onPress={onButtonClick}
            >
              <p className="text-lg font-lfg-book line-clamp-2">
                {modifierItem.name}
              </p>
              {modifierItem.price !== 0 && (
                <p className="text-lg font-lfg-book mt-1 text-gray-500">
                  {usdFormatter.format(modifierItem.price / 100)}
                </p>
              )}
            </Tappable>
          </div>
        </div>
      </div>
    </li>
  );
};
