import { Tip } from "./Tip";

import { Cart } from "../../types";

type TipControllerProps = {
  isManager: boolean;
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

export const TipController = ({
  isManager,
  cart,
  onCartChange,
}: TipControllerProps) => {
  return <Tip isManager={isManager} cart={cart} onCartChange={onCartChange} />;
};
