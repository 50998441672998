import { ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { useEffect } from "react";

const reloadPage = () => (window.location.href = "/");
const deferReloadPage = () => setTimeout(reloadPage, 10 * 1000);

export const ErrorBanner = () => {
  useEffect(() => {
    // Reload the page after 10 seconds of rendering the banner
    const timeoutId = deferReloadPage();
    // When the error banner unmounts, be sure to remove the timeout as well.
    // i.e. app resolves the error and reload is no longer necessary.
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div
      title="error-banner"
      className="fixed inset-x-0 top-0 z-50"
      onClick={reloadPage}
    >
      <div className="bg-red-600">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-red-800">
                <ExclamationIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-lfg-book text-white truncate">
                Reader Error: Click to refresh the App
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <button className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-lfg-book text-red-600 bg-white hover:bg-red-50">
                Refresh
              </button>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Refresh</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBanner;
