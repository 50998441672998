import React from "react";
import { useDoubleTap } from "use-double-tap";

const style = {
  borderTopColor: "#000000",
};

type LoadingProps = {
  preventReload?: boolean;
};

const Loading = ({ preventReload }: LoadingProps) => {
  const handleDoubleClick = useDoubleTap(() => {
    window.location?.reload();
  });

  return (
    <section
      title="loading"
      className="flex h-screen w-screen justify-center items-center border-gray-900"
    >
      {preventReload ? (
        <div
          style={style}
          className="animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"
        />
      ) : (
        <div
          style={style}
          {...handleDoubleClick}
          className="animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"
        />
      )}
    </section>
  );
};
export default Loading;
