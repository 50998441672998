import * as Sentry from "@sentry/react";
import AppDisconnected from "../components/AppDisconnected";
import Loading from "../components/Loading";
import LocationContext from "../context/LocationContext";
import { Home } from "../features/Home";
import { useLocationAccount } from "../hooks/useLocationAccount";
import { useLocationInfo } from "../hooks/useLocationInfo";

const DEFAULT_LOCATION = "lafayette";

type LocationProps = {
  userEmail: string;
};

const Location = ({ userEmail }: LocationProps) => {
  // Which location to set based on the account
  const { data: locationAccountData, loading: isLocationAccountLoading } =
    useLocationAccount(userEmail);

  const locationSlug =
    locationAccountData?.location_account?.location?.slug || DEFAULT_LOCATION;

  // Info about specific location (i.e. tax rate)
  const { data: locationInfoData, loading: isLocationInfoLoading } =
    useLocationInfo(locationSlug);

  const taxRate = locationInfoData?.store_location?.tax_rate || 0;
  const stripeLocationId =
    locationInfoData?.store_location?.stripe_location_id || "";
  const recentPrepTime = locationInfoData?.store_location?.recent_prep_time;

  if (isLocationInfoLoading || isLocationAccountLoading) {
    return <Loading />;
  }

  if (!taxRate) {
    Sentry.captureException("Kiosk: location tax rate not set for location.");
    return <AppDisconnected />;
  }

  if (!stripeLocationId) {
    Sentry.captureException(
      `Kiosk: stripe location not set for location [ ${locationSlug} ].`,
    );
    return <AppDisconnected />;
  }

  return (
    <LocationContext.Provider value={{ taxRate, locationSlug, recentPrepTime }}>
      <Home locationSlug={locationSlug} />
    </LocationContext.Provider>
  );
};

export default Location;
