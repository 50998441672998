export const paginateItems = (
  items: any,
  totalPageCapacity: any,
  sortItems?: boolean,
) => {
  const itemPages: any = [[]];

  let currentPageCapacity = totalPageCapacity;
  let currentPageIndex = 0;

  let allItems = [...items];
  if (sortItems) {
    const activeItems: any[] = [];
    const inactiveItems: any[] = [];
    allItems.forEach((item: any) => {
      if (item.is_active) {
        activeItems.push(item);
      } else {
        inactiveItems.push(item);
      }
    });
    allItems = [...activeItems, ...inactiveItems];
  }

  allItems.forEach((item: any) => {
    if (currentPageCapacity > 0) {
      if (itemPages[currentPageIndex]) {
        itemPages[currentPageIndex].push(item);
      } else {
        itemPages[currentPageIndex] = [item];
      }
      currentPageCapacity -= 1;
    } else {
      currentPageCapacity = totalPageCapacity;
      currentPageIndex += 1;

      itemPages[currentPageIndex] = [item];
      currentPageCapacity -= 1;
    }
  });

  return itemPages;
};
