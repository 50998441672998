import React from "react";
import { Location } from "../../types";
import { PrepTimeEstimate } from "../PrepTimeEstimate";
import { BackButton } from "../../components/BackButton";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";

interface LocationChooserProps {
  locations: Location[];
  updateLocationAccount: (location_id: number) => Promise<void>;
  loading: boolean;
}

const LocationChooser = ({
  locations,
  loading,
  updateLocationAccount,
}: LocationChooserProps) => {
  const history = useHistory();
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen flex-col">
      <div className="mx-auto w-5/12 px-4">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="overflow-y-scroll h-screen-with-header px-4">
          {locations.map((location) => (
            <div className="mt-8" key={location.slug}>
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-purple-500"
                  onClick={async () => {
                    await updateLocationAccount(location.id);
                  }}
                >
                  {location.name}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationChooser;
