import * as Sentry from "@sentry/react";
import { Terminal } from "@stripe/terminal-js";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCapturePaymentIntent } from "../../hooks/useCapturePaymentIntent";
import { useCreateInPersonPaymentIntent } from "../../hooks/useCreateInPersonPaymentIntent";
import { useStoreOrders } from "../../hooks/useStoreOrders";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart, LineItem, Location } from "../../types";
import { CreditCard } from "./CreditCard";
import { useFulfillInPersonOrder } from "./hooks/useFulfillInPersonOrder";
import { Manager } from "./Manager";

export const KIOSK_PROVIDER_ID = 7;

const getPaymentIntentDescription = (cart: Cart) => {
  return cart.line_items
    .map((item) => {
      return `${item.quantity} ${item.name}`;
    })
    .join(", ");
};

const getTotalItemPrice = (cartItem?: LineItem): number => {
  if (!cartItem) return 0;

  return (
    cartItem.price +
    cartItem.modifier_items
      .map((modfier) =>
        modfier.quantity ? modfier.quantity * modfier.price : 0,
      )
      .reduce((sum, price) => sum + price, 0)
  );
};

const getCartSubtotal = (cart: Cart): number => {
  return cart.line_items
    .map((item) => getTotalItemPrice(item) * item.quantity)
    .reduce((sum, price) => sum + price, 0);
};

const getBrandCount = (items: LineItem[]) => {
  return items
    ? items
        .map((item) => item?.brand_name)
        .filter((brandName) => brandName && brandName !== "Local Kitchens")
        .filter((value, idx, arr) => arr.indexOf(value) === idx).length // filter for uniques only
    : 0;
};

type CreditCardControllerProps = {
  isManager: boolean;
  cart: Cart;
  terminal?: Terminal;
  onCartChange: (cart: Cart) => void;
  storeLocation: any;
};

export const getPaymentInfo = (cart: Cart, location?: Location) => {
  const subtotal = getCartSubtotal(cart);
  const serviceFeeAmount = 0;
  // tax should be applied to subtotal plus service fee
  const taxAmount = Math.round(
    (subtotal + serviceFeeAmount) * (cart.tax_rate * 0.0001),
  );
  const tipAmount = Math.round(subtotal * (cart?.tip_rate * 0.0001));
  const total = subtotal + tipAmount + taxAmount + serviceFeeAmount;

  return { total, subtotal, taxAmount, tipAmount, serviceFeeAmount };
};

export const CreditCardController = ({
  isManager,
  cart,
  terminal,
  onCartChange,
  storeLocation,
}: CreditCardControllerProps) => {
  const history = useHistory();
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [hasCustomerOrderedPrev, setHasCustomerOrderedPrev] = useState(false);
  const { track } = useTrackWithFlags();
  const { data: storeOrderData } = useStoreOrders(cart?.phone_number);

  useEffect(() => {
    // the guest has not yet ordered, so we just need greater than 0
    if (storeOrderData && storeOrderData?.order_history_by_phone.length > 0) {
      setHasCustomerOrderedPrev(true);
    } else if (storeOrderData?.order_history_by_phone.length === 0) {
      setHasCustomerOrderedPrev(false);
    }
  }, [storeOrderData]);

  const { createInPersonPaymentIntent, loading: loadingCreate } =
    useCreateInPersonPaymentIntent();
  const { capturePaymentIntent, loading: loadingCapture } =
    useCapturePaymentIntent();

  const { fulfillInPersonOrder, loading: loadingFulfill } =
    useFulfillInPersonOrder();

  const {
    total: amount,
    subtotal,
    taxAmount,
    tipAmount,
    serviceFeeAmount,
  } = getPaymentInfo(cart, storeLocation);

  const onCollectPayment = async () => {
    track({
      event: "Place Order Attempted",
      properties: {
        providerId: KIOSK_PROVIDER_ID,
        cart,
      },
    });

    if (!terminal) {
      return null;
    }

    const description = getPaymentIntentDescription(cart);

    const paymentIntentResponse = await createInPersonPaymentIntent({
      variables: {
        cart_id: cart.id,
        amount,
        description,
        stripe_payment_intent_id: cart.stripe_payment_intent_id,
      },
    });

    const clientSecret =
      paymentIntentResponse?.data?.create_in_person_payment_intent?.secret;

    const paymentIntentId =
      paymentIntentResponse?.data?.create_in_person_payment_intent?.intent;

    if (!clientSecret || !paymentIntentId) {
      Sentry.captureException(
        `Failed to create payment intent for cart ${cart?.id}`,
      );
      return null;
    }

    const updatedCart = {
      ...cart,
      stripe_payment_intent_id: paymentIntentId,
    };

    onCartChange(updatedCart);

    const result = await terminal.collectPaymentMethod(clientSecret);

    if ("error" in result) {
      // Force re-render of the child component
      setStripeClientSecret(clientSecret);
      Sentry.captureException(
        `Failed to collect payment for cart ${cart?.id} with error: ${result.error?.message}`,
      );
      return null;
    }

    if (result.paymentIntent) {
      const processPaymentResult = await terminal.processPayment(
        result.paymentIntent,
      );

      if ("error" in processPaymentResult) {
        // Force re-render of the child component
        setStripeClientSecret(clientSecret);
        Sentry.captureException(
          `Failed to process payment for cart ${cart?.id} with error: ${processPaymentResult.error?.message}`,
        );
        return null;
      }

      const processPaymentIntentId =
        processPaymentResult.paymentIntent.id || "";
      const paymentIntent = await onCapturePaymentIntent(
        processPaymentIntentId,
      ).catch(() => {
        Sentry.captureException(
          `Failed to capture payment intent for cart ${cart?.id}`,
        );
      });

      const updatedCart = {
        ...cart,
        subtotal: subtotal,
        points_earned: subtotal,
        tip_amount: tipAmount,
        tax_amount: taxAmount,
        service_fee_rate: 0,
        service_fee_amount: 0,
        total: amount,
        stripe_payment_intent_id: paymentIntent,
      };

      const res = await fulfillInPersonOrder({
        variables: { cart: JSON.stringify(updatedCart) },
      });

      if (res?.data?.fulfill_in_person_order?.status !== "200") {
        Sentry.captureException(
          `Failed to fulfill order for cart ${cart?.id} with status: ${res?.data?.fulfill_in_person_order?.status}`,
        );
      }

      onCartChange(updatedCart);

      const products = cart.line_items.map((item: LineItem) => ({
        product_id: item.id,
        sku: item.id,
        name: item.name,
        brand: item.brand_name,
        price: item.price / 100,
        quantity: item.quantity,
      }));
      const orderCompletedProperties = {
        cart,
        orderId: cart.id,
        checkoutId: paymentIntent,
        brandCount: getBrandCount(cart.line_items),
        subtotal: subtotal / 100,
        tax: taxAmount / 100,
        total: amount / 100,
        value: amount / 100, // see https://segment.com/docs/connections/spec/ecommerce/v2/
        location: cart.location,
        providerId: KIOSK_PROVIDER_ID,
        checkoutCarouselItemCount: cart.line_items.filter(
          (line_item) => line_item.is_checkout_carousel_item,
        ).length,
        products,
        currency: "USD",
      };

      track({
        event: "Order Completed",
        properties: orderCompletedProperties,
      });

      history.push("/thank-you");

      return null;
    }

    return null;
  };

  const onCapturePaymentIntent = async (paymentIntentId: string) => {
    const res = await capturePaymentIntent({
      variables: {
        payment_intent_id: paymentIntentId,
      },
    });

    const paymentIntent = res?.data?.capture_payment_intent;

    if (!paymentIntent) {
      return null;
    }

    return paymentIntent.intent;
  };

  if (isManager) {
    return (
      <Manager
        cart={cart}
        subtotal={subtotal}
        total={amount}
        tipAmount={tipAmount}
        taxAmount={taxAmount}
        serviceFeeAmount={serviceFeeAmount}
        serviceFeeRate={0}
        fulfillInPersonOrder={fulfillInPersonOrder}
      />
    );
  }

  return (
    <CreditCard
      key={stripeClientSecret}
      terminal={terminal}
      onCollectPayment={onCollectPayment}
      cart={cart}
      subtotal={subtotal}
      total={amount}
      tipAmount={tipAmount}
      taxAmount={taxAmount}
      serviceFeeAmount={serviceFeeAmount}
      loading={loadingCapture || loadingCreate || loadingFulfill}
      serviceFeeRate={0}
    />
  );
};
