import {
  isDev,
  localDatetimeOverride,
  useLocalDatetimeOverride,
} from "../constants";
import { ItemsMap, Menu, MenuItem } from "../types";

type IPublicMenus = {
  publicMenus: Menu[];
  itemsMap: ItemsMap;
};

export const getCurrentDatetime = (): Date => {
  if (isDev && useLocalDatetimeOverride) {
    return new Date(localDatetimeOverride);
  } else {
    return new Date();
  }
};

const getTimestamp = (time: any) => {
  const [hours, minutes] = time.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
};

const isStoreOpen = (store: any, _currentDateTime: any) => {
  if (store.brand.slug === "popular") {
    return true;
  }

  return store.is_open && store.is_within_hours;
};

export const getTaggedItems = (
  publicStores: any,
  tagData: any,
  currentTime: any,
  isManager: boolean,
) => {
  const openPublicStores = isManager
    ? publicStores
    : publicStores.filter((store: any) => isStoreOpen(store, currentTime));

  return openPublicStores
    .map((store: any) => {
      const taggedIds = (tagData?.menu_items || []).map((item: any) => item.id);

      return matchMenuItems(store, taggedIds).map((menuItem: any) => {
        const [tagMenuItem] = tagData.menu_items.filter(
          (item: any) => item.id === menuItem.id,
        );

        return {
          ...menuItem,
          count: tagMenuItem.count,
          brand_name: store.brand.name,
          brand_slug: store.brand.slug,
          store_id: store.id,
          is_open: isStoreOpen(store, currentTime),
        };
      });
    })
    .flat()
    .sort((a: any, b: any) => b.count - a.count);
};

export const matchMenuItems = (store: any, matchingIds: number[]) => {
  return store.menus[0].menu_categories
    .map((category: any) => category.menu_items)
    .flat()
    .filter((item: any) => matchingIds.includes(item.id));
};

export const getPublicMenus = (
  menuData: any,
  isManager: boolean,
  tagsData: any,
): IPublicMenus => {
  const currentTime = getCurrentDatetime();
  const nonManagerStores = menuData?.public_stores || [];
  const managerStores = menuData?.private_stores || [];
  const publicStores = isManager ? managerStores : nonManagerStores;

  const getMostPopularItems = (store: any) => {
    const mostPopularIds = store.most_popular.map((item: any) => item.id);
    return store.menus[0].menu_categories
      .map((category: any) => category.menu_items)
      .flat()
      .filter((item: any) => mostPopularIds.includes(item.id))
      .map((menuItem: any) => {
        const [mostPopular] = store.most_popular.filter(
          (item: any) => item.id === menuItem.id,
        );
        return {
          ...menuItem,
          count: mostPopular.count,
          brand_name: store.brand.name,
          brand_slug: store.brand.slug,
          store_id: store.id,
          is_open: isStoreOpen(store, currentTime),
        };
      });
  };

  const mostPopularItems = publicStores
    .map((store: any) => {
      return getMostPopularItems(store);
    })
    .flat()
    .sort((a: any, b: any) => b.count - a.count);

  const collections = (
    tagsData?.location_tags.filter((tag: any) => tag.is_collection) || []
  )
    .map((tagData: any) => {
      const taggedItems = getTaggedItems(
        publicStores,
        tagData,
        currentTime,
        isManager,
      );

      return {
        ...tagData,
        collection_items: taggedItems,
      };
    })
    .sort((tagA: any, tagB: any) => tagA.ordinal - tagB.ordinal);
  const getPopularItemsFromOpenStores = (publicStores: any) => {
    let popularItems: any = [];

    // In Manager mode, closed stores are available
    const openPublicStores = isManager
      ? publicStores
      : publicStores.filter((store: any) => store.is_open);
    openPublicStores.forEach((openPublicStore: any) => {
      const mostPopular = getMostPopularItems(openPublicStore);
      popularItems = popularItems.concat(mostPopular);
    });

    return popularItems.sort((a: any, b: any) => b.count - a.count);
  };

  const stores: any = [];
  const allItems: any = [];
  const itemsMap: ItemsMap = {};

  publicStores.forEach((store: any) => {
    stores.push({
      ...store,
      is_open: isStoreOpen(store, currentTime),
    });

    store.menus.forEach((menu: any) => {
      menu.menu_categories.forEach((menuCategory: any) => {
        menuCategory?.menu_items.forEach((menuItem: any) => {
          allItems.push(menuItem);
        });
      });
    });
  });

  const openPublicStores = isManager
    ? stores
    : stores.filter((store: any) => store.is_open);
  const hasManyStoresClosed =
    openPublicStores.length < Math.floor(publicStores.length * 0.6);

  const mostPopular = {
    id: 0,
    brand: {
      id: 0,
      name: "Collections",
      slug: "popular",
    },
    is_open: true,
    location: publicStores[0]?.location,
    menus: [],
    store_hours: [],
    most_popular:
      hasManyStoresClosed || openPublicStores.length === 1
        ? getPopularItemsFromOpenStores(stores)
        : sortMostPopular(mostPopularItems),
    collections,
  };

  allItems.forEach((item: any) => {
    const itemId = item?.id || "";
    itemsMap[itemId] = item;
  });

  const rankedStores = rankPublicMenus(stores, undefined, isManager);
  const publicMenus = [mostPopular].concat(rankedStores);
  return { publicMenus, itemsMap };
};

const sortMostPopular = (items: any): any => {
  const brandsRepresented: { [key: string]: boolean } = {};
  const topN: any = [];
  const theRest: any = [];

  items.forEach((item: any) => {
    if (brandsRepresented[item.brand_slug]) {
      theRest.push(item);
    } else {
      brandsRepresented[item.brand_slug] = true;
      topN.push(item);
    }
  });

  const mostPopular = [...topN, ...theRest];
  const prioritizedItems = prioritizeAvailableItems(mostPopular);
  return prioritizedItems;
};

export const prioritizeAvailableItems = (items: MenuItem[]): MenuItem[] => {
  const unavailableItems: MenuItem[] = [];
  const availableItems: MenuItem[] = [];
  items.forEach((item: MenuItem): void => {
    if (item.is_open) {
      availableItems.push(item);
    } else {
      unavailableItems.push(item);
    }
  });
  return [...availableItems, ...unavailableItems];
};

const rankPublicMenus = (
  publicMenus: any,
  mostPopularItems?: any,
  isManager?: boolean,
) => {
  const openMenus = isManager
    ? publicMenus
    : publicMenus.filter((menu: any) => menu.is_open);
  const closedMenus = isManager
    ? []
    : publicMenus.filter((menu: any) => !menu.is_open);

  const sortedOpenMenus = sortMenu(openMenus);
  const sortedClosedMenus = sortMenu(closedMenus);

  return sortedOpenMenus.concat(sortedClosedMenus);
};

/**
 * if 'mostPopularItems' is present, it will sort menus based on the brand
 * with more sales
 * if not, it will keep the current order
 */
const sortMenu = (menus: any, mostPopularItems?: any) => {
  let rankedBrands: any[] = [];
  if (mostPopularItems) {
    const mostPopularBrands: any = new Set(
      mostPopularItems.map((popularItem: any) => popularItem.brand_slug),
    );
    rankedBrands = [...mostPopularBrands];
  }
  const menusWithPoints: any = [];

  menus.forEach((menu: any) => {
    if (menu.brand.slug === "popular") {
      menusWithPoints.push({ ...menu, points: -1 });
    } else if (menu.brand.slug === "localkitchens") {
      menusWithPoints.push({ ...menu, points: 1000 });
    } else {
      menusWithPoints.push({ ...menu, points: menu.ordinal });
    }
  });

  const sorted = menusWithPoints.sort((a: any, b: any) => a.points - b.points);
  return sorted;
};
