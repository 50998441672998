type MenuItemPageSelectorProps = {
  currentPageIndex: number;
  menuItemPages: Array<any>;
  onMenuItemPagePrevious: (currentPageIndex: number) => void;
  onMenuItemPageNext: (currentPageIndex: number) => void;
};

export const MenuItemPageSelector = ({
  currentPageIndex,
  menuItemPages,
  onMenuItemPagePrevious,
  onMenuItemPageNext,
}: MenuItemPageSelectorProps) => {
  let pageDotClass = "w-6 h-6";

  if (menuItemPages.length > 10) {
    pageDotClass = "w-4 h-4";
  }

  if (menuItemPages.length > 20) {
    pageDotClass = "w-2 h-2";
  }

  return (
    <div className="flex flex-col" style={{ minHeight: 88 }}>
      <div className="flex justify-between p-4 pb-0">
        <button
          className={`${
            currentPageIndex > 0 ? "block" : "hidden"
          } swiper-button-prev w-28 h-auto rounded bg-lfg-primary text-white font-lfg-book flex items-center justify-center`}
          onClick={() => onMenuItemPagePrevious(currentPageIndex)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {currentPageIndex === 0 && <div className="w-28 h-14" />}
        <ul className="flex-1 flex justify-center">
          {menuItemPages.map((_menuItemPage: any, index: number) => (
            <li key={index}>
              <div
                className={`${pageDotClass} rounded-full ${
                  index === currentPageIndex
                    ? "bg-lfg-primary text-white"
                    : "bg-gray-300 text-white"
                } flex items-center justify-center mr-2 font-lfg-book`}
              ></div>
            </li>
          ))}
        </ul>
        <button
          className={`${
            currentPageIndex < menuItemPages.length - 1 ? "block" : "hidden"
          } swiper-button-next w-28 py-2 rounded bg-lfg-primary text-white font-lfg-book flex items-center justify-center`}
          onClick={() => onMenuItemPageNext(currentPageIndex)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {currentPageIndex === menuItemPages.length - 1 && (
          <div className="w-28 h-14" />
        )}
      </div>
    </div>
  );
};
