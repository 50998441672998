import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Cart } from "../../types";

type PickupDateTimePickerProps = {
  setIsScheduling: any;
  isScheduling: boolean;
  fulfillOrder: (cart: Cart) => void;
  cart: Cart;
  providerSlug: string;
};

const onCateringProviderSubmit = (
  fulfillOrder: any,
  slug: string,
  cart: Cart,
  pickupDateTime: Date,
) => {
  const unixedPickupTime = Math.round(
    new Date(pickupDateTime).getTime() / 1000,
  );
  fulfillOrder({
    ...cart,
    type: "CATERING",
    provider: slug,
    pickup_time: unixedPickupTime,
  });
};

export const PickupDateTimePicker = ({
  setIsScheduling,
  isScheduling,
  fulfillOrder,
  providerSlug,
  cart,
}: PickupDateTimePickerProps) => {
  const [pickupDateTime, setPickupDateTime] = useState(new Date());
  const unixed = Math.round(new Date(pickupDateTime).getTime() / 1000);
  return (
    <>
      <div className="mt-8">
        <DatePicker
          selected={pickupDateTime}
          onChange={(date: Date) => setPickupDateTime(date)}
          dateFormat="MM/dd/yyyy h:mm aa"
          className="w-full border-b border-2 px-6 py-4"
          showTimeInput
        />
      </div>
      <div className="mt-8">
        <div className="rounded-md shadow">
          <button
            className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-green-900"
            onClick={() =>
              onCateringProviderSubmit(
                fulfillOrder,
                providerSlug,
                cart,
                pickupDateTime,
              )
            }
          >
            Submit
          </button>
        </div>
      </div>
      <div className="mt-8">
        <div className="rounded-md shadow">
          <button
            className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-gray-900"
            onClick={() => setIsScheduling(!isScheduling)}
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
