import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";

const UPDATE_LOCATION_ACCOUNT_MUTATION = gql`
  mutation UPDATE_LOCATION_ACCOUNT_MUTATION(
    $location_account_id: Int
    $email: String
    $location_id: Int
  ) {
    update_location_account(
      location_account_id: $location_account_id
      email: $email
      location_id: $location_id
    )
  }
`;

export const useUpdateLocationAccount = () => {
  const history = useHistory();
  const [updateLocationAccountMutation, { loading }] = useMutation(
    UPDATE_LOCATION_ACCOUNT_MUTATION,
    {
      onCompleted: () => {
        history.push("/menu");
      },
      refetchQueries: ["KioskLocationAccountQuery"],
    },
  );

  return { updateLocationAccountMutation, loading };
};
