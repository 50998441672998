import React, { useEffect } from "react";

type LogoutProps = {
  onLogOut: () => void;
};
const Logout = ({ onLogOut }: LogoutProps) => {
  useEffect(() => {
    onLogOut();
  }, []);

  return null;
};

export default Logout;
