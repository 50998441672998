import { ResponsiveContentfulImage } from "../../components/ResponsiveContentfulImage";
import { Tappable } from "../../components/Tappable";

type CollectionsSelectorProps = {
  brands: any;
  collections: any[];
  currentCollection: string;
  onCollectionChange: (collection: string) => void;
};

const CollectionsSelector = ({
  brands,
  collections,
  currentCollection,
  onCollectionChange,
}: CollectionsSelectorProps) => {
  return (
    <div
      className="mx-4 flex mb-2 border-t border-b border-l border-green-800"
      style={{ minHeight: 50 }}
    >
      <Tappable
        className={`${
          currentCollection === "popular"
            ? "bg-green-800 text-white"
            : "bg-white text-green-800"
        } flex-1 py-3 font-semibold border-r border-green-800 text-center cursor-pointer`}
        onPress={() => onCollectionChange("popular")}
      >
        <div className="flex items-center justify-center h-full">
          <div className="rounded-full bg-white h-8 w-8 flex justify-center items-center">
            <img
              className="w-8 rounded-full"
              src={brands?.popular?.roundLogo?.url}
              alt="Popular"
            />
          </div>
          <span className="ml-2 pr-4">Most Popular</span>
        </div>
      </Tappable>
      {(collections || []).map((collection: any, index: number) => (
        <Tappable
          key={index}
          className={`${
            currentCollection === collection.slug
              ? "bg-green-800 text-white"
              : "bg-white text-green-800"
          } flex-1 py-3 font-semibold border-r border-green-800 text-center cursor-pointer`}
          onPress={() => onCollectionChange(collection.slug)}
        >
          <div className="flex items-center justify-center h-full">
            <div className="rounded-full bg-white h-8 w-8 flex justify-center items-center">
              <ResponsiveContentfulImage
                className="w-8 rounded-full"
                contentfulUrl={
                  brands[collection.slug]?.logo?.url ||
                  brands[collection.slug]?.roundLogo?.url
                }
                blurhash={brands[collection.slug]?.logo?.blurhash}
              />
            </div>
            <span className="ml-2 pr-4">{collection.name}</span>
          </div>
        </Tappable>
      ))}
    </div>
  );
};

export default CollectionsSelector;
