import { gql, useQuery } from "@apollo/client";

export const TAG_MENU_ITEMS_QUERY = gql`
  query TagQuery(
    $location_slug: String
    $filter_active_modifiers: Boolean
    $slug: String
  ) {
    tag(slug: $slug) {
      menu_items(location_slug: $location_slug) {
        id
        name
        description
        brand_name
        brand_slug
        brand_id
        menu_item_brand_id
        price
        is_active
        blurhash
        image_url
        store_id
        count
        tags {
          id
          name
          is_active
          slug
        }
        modifier_lists {
          id
          name
          min
          max
          is_single_select
          modifier_items(filter_active_modifiers: $filter_active_modifiers) {
            id
            name
            price
            is_active
          }
        }
      }
    }
  }
`;

export const useTagMenuItems = (tagSlug: string, locationSlug: string) => {
  const { data } = useQuery(TAG_MENU_ITEMS_QUERY, {
    variables: {
      location_slug: locationSlug,
      slug: tagSlug,
      filter_active_modifiers: false,
    },
    skip: !locationSlug,
  });

  return data?.tag?.menu_items || [];
};
