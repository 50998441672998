import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Modal } from "./Modal";

type IdleModalProps = {
  onFinish: any;
  onClose: any;
};

const DEFAULT_IDLE_MODAL_SECONDS = 10;

export const IdleModal = ({ onFinish, onClose }: IdleModalProps) => {
  const [timer, setTimer] = useState<any>(undefined);

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      onClose();
      onFinish();
    }, DEFAULT_IDLE_MODAL_SECONDS * 1000);

    setTimer(refreshTimer);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, []);

  const onPresent = () => {
    if (timer) {
      clearTimeout(timer);
      onClose();
    }
  };

  return (
    <Modal open={true} onClose={onPresent}>
      <Dialog.Title
        as="h3"
        className="text-4xl leading-6 font-lfg-book mb-8 mt-4"
      >
        Are you still there?
      </Dialog.Title>
      <div className="mt-16 flex flex-end">
        <button
          type="button"
          className="text-2xl py-4 px-16 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-green-500 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto"
          onClick={onPresent}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};
