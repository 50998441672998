type ModifierItemPageSelectorProps = {
  currentPageIndex: number;
  modifierItemPages: any;
  setCurrentPageIndex: any;
};

export const ModifierItemPageSelector = ({
  currentPageIndex,
  modifierItemPages,
  setCurrentPageIndex,
}: ModifierItemPageSelectorProps) => {
  return (
    <div>
      {modifierItemPages.length > 1 ? (
        <div className="flex flex-col">
          <div className="flex justify-between p-4">
            <button
              onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
              className={`${
                currentPageIndex > 0 ? "block" : "hidden"
              } swiper-button-prev w-28 h-14 rounded bg-gray-700 text-white font-lfg-book flex items-center justify-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {currentPageIndex === 0 && <div className="w-28 h-14" />}
            <ul className="flex-1 flex justify-center">
              {modifierItemPages.map(
                (_modifierItemPage: any, index: number) => (
                  <li key={index}>
                    <div
                      className={`w-6 h-6 rounded-full ${
                        index === currentPageIndex
                          ? "bg-gray-700 text-white"
                          : "bg-gray-300 text-white"
                      } flex items-center justify-center mr-2 font-lfg-book`}
                    ></div>
                  </li>
                ),
              )}
            </ul>
            <button
              onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
              className={`${
                currentPageIndex < modifierItemPages.length - 1
                  ? "block"
                  : "hidden"
              } swiper-button-next w-28 h-14 rounded bg-gray-700 text-white font-lfg-book flex items-center justify-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {currentPageIndex === modifierItemPages.length - 1 && (
              <div className="w-28 h-14" />
            )}
          </div>
        </div>
      ) : (
        <div className="p-4" />
      )}
    </div>
  );
};
