import { Reader } from "@stripe/terminal-js";
import { useState } from "react";

type SettingsProps = {
  onDiscoverReaders: () => Promise<Array<Reader> | null>;
  onConnectReader: (reader: Reader) => Promise<null>;
  onDisconnectReader: () => Promise<null>;
  onCollectPayment: (amount: number) => Promise<string | null>;
  onCapturePaymentIntent: (paymentIntentId: string) => Promise<string | null>;
  onBackToHome: () => void;
  onLogOut: () => void;
};

export const Settings = ({
  onBackToHome,
  onConnectReader,
  onDisconnectReader,
  onDiscoverReaders,
  onCollectPayment,
  onLogOut,
}: SettingsProps) => {
  const [readers, setReaders] = useState<Array<Reader>>([]);

  const onDiscoverReadersClick = async () => {
    const readers = await onDiscoverReaders();

    if (readers) {
      setReaders(readers);
    }
  };

  const onConnectReaderClick = async (reader: Reader) => {
    onConnectReader(reader);
  };

  return (
    <div>
      <h1>Settings</h1>
      <div className="flex flex-col">
        <button onClick={onBackToHome}>Back to Home</button>
        <button onClick={onDiscoverReadersClick}>Discover Readers</button>
        <button onClick={onDisconnectReader}>Disconnect reader</button>
        <button onClick={() => onCollectPayment(1000)}>Collect Payment</button>
        <button onClick={() => onLogOut()}>Log out</button>
        {readers.map((reader) => (
          <button onClick={() => onConnectReaderClick(reader)}>
            {reader.id} {reader.label}
          </button>
        ))}
      </div>
    </div>
  );
};
