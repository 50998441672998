import { ClockIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import LocationContext from "../../context/LocationContext";

const PREP_TIME_THRESHOLD = 25;

export const PrepTimeEstimate = () => {
  const { recentPrepTime } = useContext(LocationContext);

  return recentPrepTime && recentPrepTime >= PREP_TIME_THRESHOLD ? (
    <div className="fixed font-lfg-medium text-xl left-5 bottom-5 flex flex-row justify-center items-center gap-x-2 text-blue">
      <ClockIcon className="h-10 w-10" />
      We're busy! Estimated wait time is {recentPrepTime} minutes
    </div>
  ) : null;
};
