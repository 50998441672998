import { Tappable } from "../../../components/Tappable";
import { LineItem, ModifierItem } from "../../../types";
import { usdFormatter } from "../../../utils/usdFormatter";
import { getTotalItemPrice } from "../utils/accounting";

type ActiveLineItemProps = {
  isActive: boolean;
  item: LineItem;
  onClick: (item: LineItem) => void;
};

export const getModifierDescription = (modifier: ModifierItem) => {
  if (modifier.quantity === 1) {
    return modifier.name;
  }
  return `${modifier.quantity}x ${modifier.name}`;
};

export const ActiveLineItem = ({
  isActive,
  item,
  onClick,
}: ActiveLineItemProps) => {
  const modifiersDescription = item.modifier_items
    .map((modifierItem) => getModifierDescription(modifierItem))
    .join(", ");

  return (
    <Tappable
      onPress={() => onClick(item)}
      className={`mb-4 ${isActive ? "text-gray-400" : "text-black"}`}
    >
      <button className="flex w-full text-left">
        <div className="flex w-full font-lfg-book">
          <div className="mr-2 text-xl">{item.quantity}</div>
          <div className="flex flex-col w-full">
            <div className="flex justify-between text-xl">
              <h3>{item.name}</h3>
              <div>{usdFormatter.format(getTotalItemPrice(item) / 100)}</div>
            </div>
            <p className={`${isActive ? "text-gray-400" : "text-black"}`}>
              {modifiersDescription}
            </p>
          </div>
        </div>
      </button>
    </Tappable>
  );
};
