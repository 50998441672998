import { useQuery, gql } from "@apollo/client";
import { Cart } from "../types";

export const CART_VALIDATION = gql`
  query CartValidation($cart: KioskCart) {
    kiosk_cart_validation(cart: $cart) {
      brand_id
      external_id
      id
      is_active
      name
      modifier_items {
        is_active
        name
        id
      }
    }
  }
`;

export const useCartValidation = (cart: Cart) => {
  const { data, error, loading, refetch } = useQuery(CART_VALIDATION, {
    fetchPolicy: "no-cache",
    variables: {
      cart: {
        id: cart.id,
        line_items: cart.line_items,
      },
    },
  });
  return { data, error, loading, refetch };
};
