export const renderFillers = (itemsLength: number, maxAmount: number) => {
  if (itemsLength === maxAmount) {
    return null;
  }
  const fillers: any = [];
  for (let i = 0; i < maxAmount - itemsLength; i += 1) {
    fillers.push(<div className="flex-1" />);
  }
  return fillers;
};
