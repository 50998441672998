import { CheckIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Tappable } from "../../../components/Tappable";

type StepperProps = {
  steps: Array<any>;
  onStepPress: (stepIndex: number) => void;
  modifierListIndex: number;
};

export const Stepper = ({
  modifierListIndex,
  steps,
  onStepPress,
}: StepperProps) => {
  const [activeStep, setActiveStep] = useState(steps[modifierListIndex]);
  const [stepErrors, setStepErrors] = useState<Array<boolean>>([]);

  useEffect(() => {
    const initialErrors: Array<boolean> = [];
    steps.forEach((_step) => {
      initialErrors.push(false);
    });

    setStepErrors(initialErrors);
  }, [steps.length]);

  useEffect(() => {
    setActiveStep(steps[modifierListIndex]);
  }, [steps, modifierListIndex]);

  const onStepError = (stepIndex: number) => {
    const newErrors = [...stepErrors];
    newErrors[stepIndex] = true;
    setStepErrors(newErrors);
  };

  const clearStepError = (stepIndex: number) => {
    const newErrors = [...stepErrors];
    newErrors[stepIndex] = false;
    setStepErrors(newErrors);
  };

  return (
    <nav aria-label="Progress">
      <ol className="border-t border-b border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0 px-2 mt-2">
        {steps.map((step: any, index: number) => (
          <>
            <Step
              isLastStep={index === steps.length - 1}
              showError={stepErrors[index]}
              onStepError={onStepError}
              modifierListIndex={modifierListIndex}
              step={step}
              currentIndex={index}
              activeStep={activeStep}
              onStepPress={onStepPress}
              clearStepError={clearStepError}
            />
          </>
        ))}
      </ol>
    </nav>
  );
};

type StepProps = {
  isLastStep: boolean;
  showError: any;
  onStepError: any;
  modifierListIndex: number;
  step: any;
  currentIndex: number;
  activeStep: any;
  onStepPress: (stepIndex: number) => void;
  clearStepError: (stepIndex: number) => void;
};

const Step = ({
  isLastStep,
  showError,
  onStepError,
  modifierListIndex,
  activeStep,
  step,
  currentIndex,
  onStepPress,
  clearStepError,
}: StepProps) => {
  const onPress = () => {
    if (!activeStep.isValid) {
      onStepError(modifierListIndex);
      return null;
    }

    if (
      currentIndex === modifierListIndex + 1 ||
      currentIndex < modifierListIndex
    ) {
      onStepPress(currentIndex);
      clearStepError(currentIndex);
    }
  };

  return (
    <li key={step.name} className="md:flex-1 flex items-center">
      {step.position === "before" ? (
        <BeforeStep step={step} onPress={onPress} />
      ) : step.position === "current" ? (
        <CurrentStep
          isLastStep={isLastStep}
          showError={showError}
          step={step}
          onPress={onPress}
        />
      ) : (
        <AfterStep isLastStep={isLastStep} step={step} onPress={onPress} />
      )}
    </li>
  );
};

type BeforeStepProps = {
  step: any;
  onPress: any;
};

const BeforeStep = ({ step, onPress }: BeforeStepProps) => {
  return (
    <Tappable onPress={onPress} className="relative md:flex-1 md:flex h-full">
      <a href={step.href} className="group flex items-center w-full h-full">
        <span className="px-3 py-4 flex items-center text-sm font-lfg-book">
          <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-lfg-primary rounded-full group-hover:bg-lfg-primary">
            <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
          </span>
          <span className="ml-2 text-sm font-lfg-book text-gray-900 line-clamp-2">
            {step.name}
          </span>
        </span>
      </a>
      <>
        {/* Arrow separator for lg screens and up */}
        <div
          className="hidden md:block absolute top-0 right-0 h-full w-5"
          aria-hidden="true"
        >
          <svg
            className="h-full w-full text-gray-300"
            viewBox="0 0 22 80"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0 -2L20 40L0 82"
              vectorEffect="non-scaling-stroke"
              stroke="currentcolor"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </>
    </Tappable>
  );
};

type CurrentStepProps = {
  isLastStep: boolean;
  showError: boolean;
  onPress: any;
  step: any;
};

const CurrentStep = ({
  isLastStep,
  showError,
  onPress,
  step,
}: CurrentStepProps) => {
  const borderColor = showError ? "border-red-500" : "border-lfg-primary";
  const textColor = showError ? "text-red-500" : "text-lfg-primary";

  return (
    <Tappable
      onPress={onPress}
      className="relative md:flex-1 md:flex h-full"
      aria-current="step"
    >
      <a
        href={step.href}
        className="px-3 py-4 flex items-center text-sm font-lfg-book h-full"
        aria-current="step"
      >
        <span
          className={`flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 ${borderColor} rounded-full`}
        >
          <span className={`${textColor}`}>{step.id}</span>
        </span>
        <span
          className={`ml-2 text-sm font-lfg-book ${textColor} line-clamp-2`}
        >
          {step.name}
        </span>
      </a>
      {!isLastStep && (
        <>
          {/* Arrow separator for lg screens and up */}
          <div
            className="hidden md:block absolute top-0 right-0 h-full w-5"
            aria-hidden="true"
          >
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      )}
    </Tappable>
  );
};

type AfterStepProps = {
  isLastStep: boolean;
  onPress: any;
  step: any;
};

const AfterStep = ({ isLastStep, onPress, step }: AfterStepProps) => {
  return (
    <Tappable onPress={onPress} className="relative md:flex-1 md:flex h-full">
      <a href={step.href} className="group flex items-center h-full">
        <span className="px-3 py-4 flex items-center text-sm font-lfg-book">
          <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
            <span className="text-gray-500 group-hover:text-gray-900">
              {step.id}
            </span>
          </span>
          <span className="ml-2 text-sm font-lfg-book text-gray-500 group-hover:text-gray-900 line-clamp-2">
            {step.name}
          </span>
        </span>
      </a>
      {!isLastStep && (
        <>
          {/* Arrow separator for lg screens and up */}
          <div
            className="hidden md:block absolute top-0 right-0 h-full w-5"
            aria-hidden="true"
          >
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      )}
    </Tappable>
  );
};
