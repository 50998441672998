import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { SWIPE_THRESHOLD } from "../../../constants";
import { renderFillers } from "../../../utils/renderFillers";
import "../../Menu/components/swiper.css";
import { BRAND_PER_PAGE } from "../Menu";
import BrandPage from "./BrandPage";

type BrandSelectorProps = {
  brands: any;
  currentBrandIndex: number;
  currentBrandPageIndex: number;
  brandPages: Array<any>;
  onBrandClick: (menuId: number) => void;
  setCurrentBrandPageIndex: any;
  onSlideChange: any;
  setCurrentBrandIndex: any;
  collectionName: any;
  collectionLogo: any;
};

export const BrandSelector = ({
  brands,
  currentBrandIndex,
  currentBrandPageIndex,
  brandPages,
  onBrandClick,
  onSlideChange,
  setCurrentBrandIndex,
  collectionName,
  collectionLogo,
}: BrandSelectorProps) => {
  return (
    <div className="flex items-center justify-center ml-1">
      <button
        className={`${
          currentBrandPageIndex > 0 ? "text-black" : "text-gray-400"
        } brand-swiper-button-prev flex items-center justify-center rounded mr-2 py-6`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <Swiper
        key={currentBrandPageIndex}
        initialSlide={currentBrandPageIndex}
        observer={true}
        observeParents={true}
        resizeObserver={true}
        navigation={{
          prevEl: ".brand-swiper-button-prev",
          nextEl: ".brand-swiper-button-next",
        }}
        threshold={SWIPE_THRESHOLD}
        onSlidePrevTransitionStart={(swiper) => {
          if (swiper?.activeIndex === currentBrandPageIndex) {
            return null;
          }
          setCurrentBrandIndex(BRAND_PER_PAGE - 1);
        }}
        onSlideNextTransitionStart={(swiper) => {
          if (swiper?.activeIndex === currentBrandPageIndex) {
            return null;
          }
          setCurrentBrandIndex(0);
        }}
        onSlideChangeTransitionEnd={(swiper) => {
          if (swiper?.activeIndex === currentBrandPageIndex) {
            return null;
          }

          if (swiper?.activeIndex > currentBrandPageIndex) {
            onSlideChange(swiper?.activeIndex, "next");
          } else {
            onSlideChange(swiper?.activeIndex, "prev");
          }
        }}
      >
        {brandPages.map((brandPage: any) => {
          return (
            <SwiperSlide>
              <ul className="flex flex-1 py-4 items-center">
                {brandPage.map((menu: any, index: any) => {
                  return (
                    <BrandPage
                      menu={menu}
                      brands={brands}
                      index={index}
                      currentBrandIndex={currentBrandIndex}
                      onBrandClick={onBrandClick}
                      collectionName={collectionName}
                      collectionLogo={collectionLogo}
                    />
                  );
                })}
                {renderFillers(brandPage.length, BRAND_PER_PAGE)}
              </ul>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <button
        className={`${
          currentBrandPageIndex < brandPages.length - 1
            ? "text-black"
            : "text-gray-400"
        } brand-swiper-button-next flex items-center justify-center rounded mr-1 py-6`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};
