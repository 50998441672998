import React from "react";
import Loading from "../components/Loading";
import LocationContext from "../context/LocationContext";
import { useBrands } from "../hooks/useBrands";
import { useLocationTags } from "../hooks/useLocationTags";
import { useManagerMenu, useMenu } from "../hooks/useMenu";
import { getBrandItemsMap } from "../utils/getBrandItemsMap";
import { getPublicMenus } from "../utils/getPublicMenus";
import Cart from "./Cart";

type MenuProps = {
  locationSlug: string;
  isManager: boolean;
};

const Menu = ({ locationSlug, isManager }: MenuProps) => {
  const { data: managerMenuData, loading: managerMenuLoading } = useManagerMenu(
    locationSlug,
    isManager,
  );
  const { data: publicMenuData, loading: publicMenuLoading } =
    useMenu(locationSlug);

  const menuLoading = managerMenuLoading || publicMenuLoading;
  const menuData = isManager ? managerMenuData : publicMenuData;

  // Information regarding brands such as logo url
  const { data: brandsData, loading: brandsLoading } = useBrands();

  const { data: tagsData } = useLocationTags(locationSlug);

  // Creates a map so we can look up brand info by slug
  const brands = getBrandItemsMap(brandsData);

  const { publicMenus, itemsMap } = getPublicMenus(
    menuData,
    isManager,
    tagsData,
  );

  if (menuLoading || brandsLoading) {
    return <Loading />;
  }

  return (
    <LocationContext.Consumer>
      {({ taxRate }) => (
        <Cart
          locationSlug={locationSlug}
          taxRate={taxRate}
          menus={publicMenus}
          brands={brands}
          itemsMap={itemsMap}
        />
      )}
    </LocationContext.Consumer>
  );
};

export default Menu;
