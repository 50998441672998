import { Reader, Terminal } from "@stripe/terminal-js";
import { useHistory } from "react-router-dom";
import { useCapturePaymentIntent } from "../../hooks/useCapturePaymentIntent";
import { useCreateInPersonPaymentIntent } from "../../hooks/useCreateInPersonPaymentIntent";
import { Settings } from "./Settings";

type SettingsControllerProps = {
  terminal?: Terminal;
  onLogOut: () => void;
};

export const SettingsController = ({
  terminal,
  onLogOut,
}: SettingsControllerProps) => {
  const { createInPersonPaymentIntent } = useCreateInPersonPaymentIntent();
  const { capturePaymentIntent } = useCapturePaymentIntent();
  const history = useHistory();

  const onDiscoverReaders = async () => {
    if (!terminal) {
      return null;
    }

    const readers = await terminal.discoverReaders();

    if ("error" in readers) {
      console.log("Failed to discover: ", readers.error);
      return null;
    }

    if (readers.discoveredReaders.length === 0) {
      return [];
    }

    return readers.discoveredReaders;
  };

  const onConnectReader = async (reader: Reader) => {
    if (!terminal) {
      return null;
    }

    const connectResult = await terminal.connectReader(reader);

    if ("error" in connectResult) {
      console.log("Failed to discover: ", connectResult.error);
      return null;
    }

    console.log("Connected to reader: ", connectResult.reader.label);
    return null;
  };

  const onDisconnectReader = async () => {
    if (!terminal) {
      return null;
    }

    await terminal.disconnectReader();
    return null;
  };

  const onCollectPayment = async (amount: number) => {
    if (!terminal) {
      return null;
    }

    const paymentIntentResponse = await createInPersonPaymentIntent({
      variables: {
        amount,
        description: "Kiosk payment intent",
      },
    });

    const clientSecret =
      paymentIntentResponse?.data?.create_in_person_payment_intent?.secret;

    if (!clientSecret) {
      return null;
    }

    terminal.setSimulatorConfiguration({ testCardNumber: "4242424242424242" });
    const result = await terminal.collectPaymentMethod(clientSecret);

    if ("error" in result) {
      console.log("Failed to collect payment: ", result.error);
      return null;
    }

    if (result.paymentIntent) {
      const processPaymentResult = await terminal.processPayment(
        result.paymentIntent,
      );

      if ("error" in processPaymentResult) {
        console.log("Failed to process payment: ", processPaymentResult.error);
        return null;
      }

      const processPaymentIntentId =
        processPaymentResult.paymentIntent.id || "";
      await onCapturePaymentIntent(processPaymentIntentId);

      return null;
    }

    return null;
  };

  const onCapturePaymentIntent = async (paymentIntentId: string) => {
    const res = await capturePaymentIntent({
      variables: {
        payment_intent_id: paymentIntentId,
      },
    });

    const paymentIntent = res?.data?.capture_payment_intent;

    if (!paymentIntent) {
      return null;
    }

    return paymentIntent.intent;
  };

  const onBackToHome = () => {
    history.push("/");
  };

  return (
    <Settings
      onLogOut={onLogOut}
      onBackToHome={onBackToHome}
      onDiscoverReaders={onDiscoverReaders}
      onConnectReader={onConnectReader}
      onDisconnectReader={onDisconnectReader}
      onCollectPayment={onCollectPayment}
      onCapturePaymentIntent={onCapturePaymentIntent}
    />
  );
};
