import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { SWIPE_THRESHOLD } from "../../../constants";
import { ModifierItem } from "../../../types";
import { renderFillers } from "../../../utils/renderFillers";
import "../../Menu/components/swiper.css";
import { ActiveModifierItem } from "../components/ActiveModifierItem";
import { ModifierItemPageSelector } from "../components/ModifierItemPageSelector";
import { Stepper } from "../components/Stepper";
import { MODIFIER_ITEM_PER_PAGE } from "../MenuItem";

SwiperCore.use([Navigation]);

type ModifierItemSelectorProps = {
  modifierListIndex: number;
  modifierItemsMap: any;
  modifierListSteps: any;
  currentPageIndex: number;
  setCurrentPageIndex: any;
  currentModifierList: any;
  modifierItemPages: any;
  onStepPress: (stepIndex: number) => void;
  onModifierItemClick: (
    modifierItem: ModifierItem,
    modifierListIndex: number,
  ) => void;
  onChangeModifierItemQuantity: (
    modifierItemExternalId: string,
    modifierItemQuantity: number,
  ) => void;
};

export const getModifierItemListInfo = (modifierList: any) => {
  let isOptional = true;
  let helpText = "";

  if (modifierList.min === 0 && modifierList.max === 0) {
    helpText = "Choose as many as you like";
  } else if (modifierList.min === 0 && modifierList.max > 0) {
    helpText = `Choose up to ${modifierList.max}`;
  } else if (modifierList.min === modifierList.max) {
    helpText = `Choose ${modifierList.min}`;
    isOptional = false;
  } else if (modifierList.max > modifierList.min) {
    helpText = `Choose between ${modifierList.min} and ${modifierList.max}`;
    isOptional = false;
  } else {
    helpText = `Choose at least ${modifierList.min}`;
    isOptional = false;
  }

  return { isOptional, helpText };
};

export const ModifierItemSelector = ({
  modifierListIndex,
  modifierItemsMap,
  modifierListSteps,
  currentPageIndex,
  setCurrentPageIndex,
  currentModifierList,
  modifierItemPages,
  onStepPress,
  onModifierItemClick,
  onChangeModifierItemQuantity,
}: ModifierItemSelectorProps) => {
  const { isOptional, helpText } = getModifierItemListInfo(currentModifierList);
  let modifierItemListDescription = `${helpText}${
    isOptional ? " (optional)" : " (required)"
  }`;

  let totalCountInModifierList = 0;
  modifierItemPages.flat().forEach((modifierItem: any) => {
    totalCountInModifierList +=
      modifierItemsMap[modifierItem.id]?.quantity || 0;
  });

  const goToNextStep = () => {
    onStepPress(modifierListIndex + 1);
  };

  return (
    <>
      {modifierListSteps.length > 1 && (
        <Stepper
          modifierListIndex={modifierListIndex}
          steps={modifierListSteps}
          onStepPress={onStepPress}
        />
      )}
      <div className="flex items-center justify-between px-4 pt-4">
        <div className="flex flex-col">
          <p className="text-2xl font-lfg-book">{currentModifierList.name}</p>
          <p className="text-xl">{modifierItemListDescription}</p>
        </div>
        <div className="">
          {modifierListSteps[modifierListIndex]?.isValid &&
            modifierListIndex < modifierListSteps.length - 1 && (
              <button
                className="px-4 py-3 rounded bg-lfg-primary text-white font-lfg-book flex items-center justify-center text-xl"
                onClick={goToNextStep}
              >
                Next Step
              </button>
            )}
        </div>
      </div>
      <div className="bg-white flex flex-col flex-1">
        <Swiper
          key={currentModifierList.id}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          slidesPerView="auto"
          threshold={SWIPE_THRESHOLD}
          onSlideChangeTransitionEnd={(swiper) =>
            setCurrentPageIndex(swiper?.activeIndex)
          }
        >
          {modifierItemPages.map((modifierItemPage: any) => (
            <SwiperSlide>
              <ul className="grid grid-cols-2 gap-4 flex-1 p-4 auto-rows-fr h-full">
                {modifierItemPage.map((modifierItem: any) => {
                  return (
                    <ActiveModifierItem
                      key={modifierItem.id}
                      modifierListIndex={modifierListIndex}
                      onChangeQuantity={onChangeModifierItemQuantity}
                      modifierItemQuantity={
                        modifierItemsMap[modifierItem.id]?.quantity || 0
                      }
                      modifierItemExternalId={
                        modifierItemsMap[modifierItem.id]?.external_id || ""
                      }
                      modifierList={currentModifierList}
                      modifierItem={modifierItem}
                      onModifierItemClick={onModifierItemClick}
                    />
                  );
                })}
                {renderFillers(modifierItemPage.length, MODIFIER_ITEM_PER_PAGE)}
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>

        <ModifierItemPageSelector
          currentPageIndex={currentPageIndex}
          modifierItemPages={modifierItemPages}
          setCurrentPageIndex={setCurrentPageIndex}
        />
      </div>
    </>
  );
};
