import { ArrowDownIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Cart } from "../../types";
import { usdFormatter } from "../../utils/usdFormatter";
import { getTotalItemPrice } from "../ActiveCart/utils/accounting";

type CreditCardProps = {
  cart: Cart;
  subtotal: number;
  total: number;
  tipAmount: number;
  taxAmount: number;
  serviceFeeAmount: number;
  terminal: any;
  onCollectPayment: any;
  loading: boolean;
  serviceFeeRate: number;
};

export const CreditCard = ({
  terminal,
  onCollectPayment,
  cart,
  subtotal,
  total,
  tipAmount,
  taxAmount,
  serviceFeeAmount,
  loading,
  serviceFeeRate,
}: CreditCardProps) => {
  const history = useHistory();

  useEffect(() => {
    onCollectPayment();

    return () => {
      terminal?.clearReaderDisplay();
    };
  }, []);

  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen">
      <main className="mx-auto w-5/12 px-4 mb-16 flex flex-col">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} disabled={loading} />
        </div>
        <div className="flex-1 flex flex-col" style={{ minHeight: 314 }}>
          <div className="flex items-center justify-center">
            <div className="w-16 mr-6">
              <img src="/verifone.png" />
            </div>
            <h1 className="text-4xl tracking-tight font-lfg-bold">
              <span>
                Insert, swipe, or tap
                <br /> credit card now
              </span>
            </h1>
          </div>
          <div className="mt-8 p-4 bg-white border border-gray-200">
            <ul className="flex-1 mb-8">
              {cart.line_items.map((item) => (
                <div className="flex justify-between text-xl mb-2">
                  <p className="font-lfg-semibold">
                    {item.quantity} {item.name}
                  </p>
                  <p>{usdFormatter.format(getTotalItemPrice(item) / 100)}</p>
                </div>
              ))}
            </ul>
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Subtotal</span>
              <span>{usdFormatter.format(subtotal / 100)}</span>
            </div>
            {serviceFeeAmount > 0 && (
              <div className="flex justify-between text-xl mb-2">
                <span className="font-lfg-semibold">
                  Service Fee
                  {serviceFeeAmount ? ` (${serviceFeeRate / 100}%)` : ""}
                </span>{" "}
                <span>{usdFormatter.format(serviceFeeAmount / 100)}</span>
              </div>
            )}
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Tax</span>
              <span>{usdFormatter.format(taxAmount / 100)}</span>
            </div>
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Tip</span>
              <span>{usdFormatter.format(tipAmount / 100)}</span>
            </div>
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Total</span>
              <span>{usdFormatter.format(total / 100)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <ArrowDownIcon className="text-lfg-primary h-28 w-28" />
        </div>
      </main>
    </div>
  );
};
