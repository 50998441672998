import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Brand } from "../types";

export const KIOSK_BRANDS_QUERY = gql`
  query KioskBrandsQuery {
    landingPageBrandSectionCollection {
      items {
        brandId
        brandSlug
        brandName
        cuisine
        foodHeroImage {
          url
        }
        logo {
          url
        }
        roundLogo {
          url
        }
      }
    }
    brands {
      id
      slug
      name
      logo {
        url
        blurhash
      }
      food_hero_image {
        url
        blurhash
      }
    }
    location_tags {
      id
      name
      slug
      collection_name
      logo {
        url
        blurhash
      }
      hero_image {
        url
        blurhash
      }
    }
  }
`;

export const useBrands = () => {
  const [content, setContent] = useState<Brand[]>([]);
  const { data, error, loading, refetch } = useQuery(KIOSK_BRANDS_QUERY, {
    pollInterval: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (data) {
      const mappedItems = data.landingPageBrandSectionCollection.items.map(
        (item: any) => {
          const publicBrand = data.brands.find(
            (brand: any) => brand.id === item.brandId,
          );

          const locationTag =
            !publicBrand &&
            data.location_tags.find((lt: any) => {
              return (
                lt.collection_name?.toLowerCase() === item.brandSlug ||
                lt.slug === item.brandSlug
              );
            });

          return {
            ...item,
            roundLogo: publicBrand?.logo || locationTag?.logo || item.roundLogo,
            logo: publicBrand?.logo || locationTag?.logo || item.logo,
            foodHeroImage:
              publicBrand?.food_hero_image ||
              locationTag?.hero_image ||
              item.foodHeroImage,
          };
        },
      );
      setContent(mappedItems);
    }
  }, [data]);

  return { data: content, error, loading, refetch };
};
