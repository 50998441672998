import { useDrag } from "@use-gesture/react";

type TappableProps = {
  title?: string;
  className?: any;
  onPress: any;
  children: any;
};

export const Tappable = ({
  title,
  className,
  onPress,
  children,
}: TappableProps) => {
  const bind = useDrag(({ active, movement: [mx, my] }) => {
    if (active === false && Math.abs(mx) < 50 && Math.abs(my) < 200) {
      onPress();
    }
  });

  return (
    <div
      title={title || ""}
      {...bind()}
      style={{ touchAction: "none" }}
      className={`${className} cursor-pointer`}
    >
      {children}
    </div>
  );
};
