import { useMutation, gql } from "@apollo/client";

const FULFILL_IN_PERSON_ORDER = gql`
  mutation fulfillInPersonOrder($cart: JSON) {
    fulfill_in_person_order(cart: $cart) {
      success
      status
    }
  }
`;

export const useFulfillInPersonOrder = () => {
  const [fulfillInPersonOrder, { loading, error }] = useMutation(
    FULFILL_IN_PERSON_ORDER,
  );

  return { fulfillInPersonOrder, loading, error };
};
