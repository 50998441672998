import { gql, useQuery } from "@apollo/client";

export const KIOSK_STORE_ORDER_QUERY = gql`
  query KioskStoreOrderQuery($customer_phone: String) {
    order_history_by_phone(customer_phone: $customer_phone) {
      id
    }
  }
`;

export const useStoreOrders = (customer_phone?: string | null) => {
  const { data, error, loading, refetch } = useQuery(KIOSK_STORE_ORDER_QUERY, {
    variables: { customer_phone },
    skip: !customer_phone,
  });

  return { data, error, loading, refetch };
};
