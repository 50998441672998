import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { CartValidationModal } from "../../components/CartValidationModal";
import Loading from "../../components/Loading";
import { SHOW_DINING_OPTION_LOCATIONS } from "../../config/showDiningOptionLocations";
import { useCartValidation } from "../../hooks/useCartValidation";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart, Menu } from "../../types";
import { updateName } from "../../utils/cart";
import { CheckoutCarousel } from "../CheckoutCarousel/CheckoutCarousel";
import { PrepTimeEstimate } from "../PrepTimeEstimate";

type NameProps = {
  menus: Menu[];
  isManager: boolean;
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

export const Name = ({ menus, isManager, cart, onCartChange }: NameProps) => {
  const history = useHistory();
  const [name, setName] = useState(cart.name);

  const { track } = useTrackWithFlags();
  const { data: cartValidationData, loading: cartValidationLoading } =
    useCartValidation(cart);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    track({
      event: "Name Step Completed",
      properties: { name },
    });

    const updatedCart = updateName(cart, name);
    onCartChange(updatedCart);

    history.push("/phone-number");
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  if (cartValidationLoading) {
    return <Loading preventReload />;
  }

  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen flex-col">
      <PrepTimeEstimate />
      <main className="mx-auto w-5/12 px-4 mb-16">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="text-center">
          <form onSubmit={onSubmit}>
            <h1 className="text-5xl tracking-tight font-lfg-bold">
              <span>Enter your name</span>
            </h1>
            <p className="text-2xl text-gray-500 my-4">
              First name and last initial
            </p>
            <div className="mt-8">
              <input
                required
                autoFocus
                type="name"
                name="name"
                id="name"
                className="focus:outline-none focus:ring shadow-lg block w-full text-3xl border-gray-500 rounded-md h-20 p-4"
                placeholder="First L"
                onChange={onNameChange}
                value={name}
                autoComplete="off"
              />
            </div>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button
                  type="submit"
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
      {!SHOW_DINING_OPTION_LOCATIONS.includes(cart.location) ? (
        <div className="flex flex-col mx-auto w-full px-24 items-center">
          <CheckoutCarousel
            menus={menus}
            cart={cart}
            onCartChange={onCartChange}
          />
        </div>
      ) : null}
      {!isManager && (
        <CartValidationModal
          cart={cart}
          onCartChange={onCartChange}
          showModal={cartValidationData?.kiosk_cart_validation.length !== 0}
          setShowModal={() => {}}
          data={cartValidationData?.kiosk_cart_validation}
        />
      )}
    </div>
  );
};
