import { gql, useQuery } from "@apollo/client";

export const UTENSILS_QUERY = gql`
  query UtensilsQuery($location_slug: String, $brand_slug: String) {
    public_store(location_slug: $location_slug, brand_slug: $brand_slug) {
      id
      utensils {
        id
        name
        description
        brand_name
        price
      }
    }
  }
`;

export const useUtensils = (locationSlug: string) => {
  const { data } = useQuery(UTENSILS_QUERY, {
    variables: { location_slug: locationSlug, brand_slug: "localkitchens" },
    fetchPolicy: "network-only",
  });

  return data?.public_store?.utensils || [];
};
