import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Cart } from "../types";
import InvalidItem from "./InvalidItem";
import { Modal } from "./Modal";

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  data: any;
  cart: Cart;
  onCartChange: (cart: Cart) => void;
}

export const CartValidationModal = ({
  showModal,
  setShowModal,
  data,
  onCartChange,
  cart,
}: Props) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      showClose={false}
    >
      <div className="p-5">
        <p className="text-2xl font-semibold mb-4">
          Oops! Some items in your cart just sold out!
        </p>
        {data.map((item: any, index: number) => {
          return (
            <div className="mb-5 last:mb-0">
              <InvalidItem
                key={item.external_id}
                item={item}
                onCartChange={onCartChange}
                cart={cart}
                setShowModal={setShowModal}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
