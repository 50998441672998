import { ResponsiveContentfulImage } from "../../../components/ResponsiveContentfulImage";
import { ResponsiveImage } from "../../../components/ResponsiveImage";
import { Tappable } from "../../../components/Tappable";
import { Brands, MenuItem as IMenuItem, Menu } from "../../../types";
import { usdFormatter } from "../../../utils/usdFormatter";

type MenuItemProps = {
  brands: Brands;
  currentMenu: Menu;
  currentItem: IMenuItem;
  onMenuItemClick?: (currentItem: any) => void;
  isManager: boolean;
};

const MenuItem = ({
  brands,
  currentMenu,
  currentItem,
  onMenuItemClick,
  isManager,
}: MenuItemProps) => {
  const isMostPopular = currentMenu.id === 0;
  const isItemPurchasable =
    isManager || (currentMenu.is_open && currentItem.is_open !== false);
  const isItemUnavailable = !isItemPurchasable || !currentItem.is_active;

  return (
    <Tappable
      title="menu-item-select"
      key={currentItem.id}
      onPress={() => {
        if (!isManager && isItemUnavailable) {
          return null;
        }
        onMenuItemClick?.(currentItem);
      }}
    >
      <div
        title="button-wrapper"
        role="button"
        className={`relative h-full ${
          currentItem.is_active ? "cursor-pointer" : "cursor-not-allowed"
        }`}
      >
        <div
          className={`${
            isItemPurchasable ? "bg-white" : "bg-red-100 opacity-60"
          } rounded flex flex-col border border-gray-200 h-full text-left`}
        >
          <div className="relative h-full bg-cover bg-center bg-gray-300 overflow-hidden">
            <ResponsiveImage
              brandName={currentItem.brand_name}
              itemName={currentItem.name}
              blurhashUrl={currentItem.blurhash}
              imageUrl={currentItem.image_url}
              className="w-full h-full absolute object-cover"
            />
            {isItemUnavailable && (
              <div className="absolute flex justify-center items-center inset-0 bg-opacity-75 bg-gray-600 text-gray-300 text-xl font-lfg-semibold">
                UNAVAILABLE
              </div>
            )}
          </div>
          <div
            className="p-3 flex flex-col justify-between"
            style={{ height: 130 }}
          >
            <div className="flex flex-col justify-end">
              <div className="flex justify-between items-center">
                <div className="flex flex-1 min-w-0 items-center">
                  {isMostPopular && (
                    <ResponsiveContentfulImage
                      className="w-8 rounded-full mr-1"
                      contentfulUrl={
                        brands[currentItem?.brand_slug]?.logo?.url ||
                        brands[currentItem?.brand_slug]?.roundLogo?.url
                      }
                      blurhash={brands[currentItem?.brand_slug]?.logo?.blurhash}
                    />
                  )}
                  <p className="text-lg font-lfg-book truncate">
                    {currentItem.name}
                  </p>
                </div>
                <div className="text-lg">
                  {usdFormatter.format(currentItem.price / 100)}
                </div>
              </div>
              <p className="text-md text-gray-500 font-lfg-book line-clamp-2 h-12">
                {currentItem.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Tappable>
  );
};

export default MenuItem;
