import { useState, useEffect } from "react";

export const useIsSafariMobile = () => {
  const [isSafariMobile, setIsSafariMobile] = useState(false);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    if (ua.indexOf("Chrome") === -1) {
      setIsSafariMobile(true);
    }
  }, []);

  return isSafariMobile;
};
