import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Tappable } from "./Tappable";

type BackButtonProps = {
  onBack: () => void;
  disabled?: boolean;
};

export const BackButton = ({ disabled, onBack }: BackButtonProps) => {
  return (
    <Tappable
      className={`h-20 flex items-center py-4 pr-12 ${
        disabled ? "opacity-70" : ""
      }`}
      onPress={disabled ? () => {} : onBack}
    >
      <ChevronLeftIcon
        className="h-10 w-10 text-lfg-primary"
        aria-hidden="true"
      />
      <span className="text-2xl text-lfg-primary font-lfg-semibold underline">
        Back
      </span>
    </Tappable>
  );
};
