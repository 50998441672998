import { useMutation, gql } from "@apollo/client";

const KIOSK_CREATE_IN_PERSON_PAYMENT_INTENT = gql`
  mutation KioskCreateInPersonPaymentIntent(
    $cart_id: String
    $amount: Int
    $description: String
    $stripe_payment_intent_id: String
  ) {
    create_in_person_payment_intent(
      cart_id: $cart_id
      amount: $amount
      description: $description
      stripe_payment_intent_id: $stripe_payment_intent_id
    ) {
      intent
      secret
    }
  }
`;

export const useCreateInPersonPaymentIntent = () => {
  const [createInPersonPaymentIntent, { loading, error }] = useMutation(
    KIOSK_CREATE_IN_PERSON_PAYMENT_INTENT,
  );

  return { createInPersonPaymentIntent, loading, error };
};
