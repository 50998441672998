import React from "react";

type ILocationContext = {
  taxRate: number;
  locationSlug: string;
  recentPrepTime?: number;
};

const LocationContext = React.createContext<ILocationContext>({
  taxRate: 0,
  locationSlug: "",
});

export default LocationContext;
