import React, { ReactElement, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import { Cart } from "../types";

interface Props {
  item: any;
  onCartChange: (cart: Cart) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  cart: Cart;
}

export default function InvalidItem({
  item,
  onCartChange,
  cart,
  setShowModal,
}: Props): ReactElement {
  const defaultLineItemClass = "text-xl font-medium";
  const history = useHistory();
  const defaultModifierItemClass = "text-md";

  const onEditClick = async () => {
    // Making a deep copy of cart
    const updatedCart = JSON.parse(JSON.stringify(cart));

    // filter mods
    const currentLineItemIndex = updatedCart.line_items.findIndex(
      (lineItem: any) => {
        return lineItem.externalId === item.externalId;
      },
    );

    const currentLineItem = updatedCart.line_items[currentLineItemIndex];
    currentLineItem.modifier_items = currentLineItem.modifier_items.filter(
      (modifierItem: any) => {
        // First, find the matching modifier_item in the Item
        const currentMod = item.modifier_items.find(
          (mod: any) => mod.id === modifierItem.id,
        );
        return currentMod?.is_active;
      },
    );

    onCartChange(updatedCart);
    setShowModal(false);
    history.push(`/menu-item/${item.id}/edit/${item.external_id}`);
  };

  const onRemoveClick = () => {
    // Making a copy of cart
    const updatedCart = JSON.parse(JSON.stringify(cart));

    // filter line items
    updatedCart.line_items = updatedCart.line_items.filter((lineItem: any) => {
      return lineItem.id !== item.id;
    });
    onCartChange(updatedCart);
    setShowModal(false);
    history.push(`/menu`);
  };

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col">
        <p
          className={`${defaultLineItemClass} ${
            item.is_active ? "" : "text-red-300 line-through"
          }`}
        >
          {item.name} {!item.is_active && "(Unavailable)"}
        </p>
        <span>
          {item.modifier_items.map((modifier_item: any, index: number) => {
            return (
              <span
                key={index}
                className={item.is_active ? "" : "text-red-300 line-through"}
              >
                <span
                  className={`${defaultModifierItemClass} ${
                    modifier_item.is_active ? "" : "text-red-300 line-through"
                  }`}
                >
                  {modifier_item.name}{" "}
                  {!modifier_item.is_active && "(Unavailable)"}
                  {/* Add a comma if we are not at the last item */}
                </span>
                {index !== item.modifier_items.length - 1 && ", "}
              </span>
            );
          })}
        </span>
      </div>
      <div>
        {/* If the item itself is still active, then show the edit button so they can change the mod. Otherwise they can remove it */}
        {item.is_active ? (
          <button
            type="button"
            onClick={onEditClick}
            className="text-lg py-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 bg-gray-700 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
          >
            Edit Item
          </button>
        ) : (
          <button
            type="button"
            onClick={onRemoveClick}
            className="text-lg py-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 bg-red-700 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
          >
            Replace
          </button>
        )}
      </div>
    </div>
  );
}
