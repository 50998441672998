import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useMemo, useState } from "react";
import { ResponsiveContentfulImage } from "../../../components/ResponsiveContentfulImage";
import { ResponsiveImage } from "../../../components/ResponsiveImage";
import UserContext from "../../../context/UserContext";
import { useTrackWithFlags } from "../../../hooks/useTrackWithFlags";
import { usdFormatter } from "../../../utils/usdFormatter";

export type Tag = {
  id: number;
  name: string;
  slug: string;
  is_active: boolean;
};

const getTagsDescriptor = (tags: Tag[]): string => {
  let descriptor = "";
  switch (tags.length) {
    case 0:
      break;
    case 1:
      descriptor = tags[0].name.toLowerCase();
      break;
    case 2:
      descriptor = tags.map((tag) => tag.name.toLowerCase()).join(" and/or ");
      break;
    default:
      const lastTag = tags[tags.length - 1];
      const firstTags = tags.slice(0, tags.length - 1);
      descriptor = `${firstTags
        .map((tag) => tag.name.toLowerCase())
        .join(", ")} and/or ${lastTag.name.toLowerCase()}`;
      break;
  }
  return descriptor;
};

const DIETARY_SLUGS = ["vegetarian", "vegan", "glutenfree"];
const DIETARY_ELEMENTS: { [key: string]: JSX.Element } = {
  vegetarian: (
    <div className="text-green-300 flex items-center text-lg font-lfg-semibold">
      <div>V</div>
      <FontAwesomeIcon className="pb-1" size="1x" icon={"leaf"} />
    </div>
  ),
  vegan: (
    <div className="text-green flex items-center text-lg font-lfg-semibold">
      <div>VG</div>
      <FontAwesomeIcon className="pb-1" size="1x" icon={"seedling"} />
    </div>
  ),
  glutenfree: (
    <div className="text-yellow font-lfg-semibold text-lg">
      <p>GF</p>
    </div>
  ),
};

type MenuItemDetailProps = {
  brands: any;
  item: any;
  currentLineItem: any;
  onChangeQuantity: (quantity: number) => void;
};

const MAX_QUANTITY = 10;
const MIN_QUANTITY = 1;

export const MenuItemDetail = ({
  brands,
  item,
  currentLineItem,
  onChangeQuantity,
}: MenuItemDetailProps) => {
  const { isManager, userEmail } = useContext(UserContext);
  const { track } = useTrackWithFlags();
  const [quantity, setQuantity] = useState(currentLineItem?.quantity || 1);
  const [showTooltip, setShowTooltip] = useState(false);
  const tags = useMemo(() => {
    return item?.tags
      ? item?.tags
          .filter((tag: Tag) => DIETARY_SLUGS.includes(tag.slug))
          .sort((a: Tag, b: Tag) => (a.slug < b.slug ? -1 : 1))
      : [];
  }, [item]);

  const onDecrease = () => {
    if (quantity > MIN_QUANTITY) {
      const newQuantity = quantity - 1;

      onChangeQuantity(newQuantity);
      setQuantity(newQuantity);
    }
  };

  const onIncrease = () => {
    if (isManager || (!isManager && quantity < MAX_QUANTITY)) {
      const newQuantity = quantity + 1;

      onChangeQuantity(newQuantity);
      setQuantity(newQuantity);
    } else if (!isManager && quantity >= MAX_QUANTITY) {
      track({
        event: "Item cap: quantity limit reached",
        properties: { isManager, userEmail, itemId: item.id },
      });
    }
  };

  useEffect(() => {
    if (!currentLineItem?.quantity) return;

    setQuantity(parseInt(currentLineItem?.quantity, 10) || 1);
  }, [currentLineItem?.quantity]);

  return (
    <div className="flex mb-4">
      <div className="w-3/12 h-auto bg-cover bg-center flex justify-center relative">
        <ResponsiveImage
          brandName={item.brand_name}
          itemName={item.name}
          imageUrl={item.image_url}
          blurhashUrl={item.blurhash}
          className="w-full h-full absolute object-cover"
        />
      </div>
      <div className="w-9/12 h-48 p-4 pb-0 flex flex-col justify-between">
        <div className="flex-1">
          <div className="flex items-center">
            <ResponsiveContentfulImage
              className="w-14 rounded-full mr-2 object-contain"
              contentfulUrl={
                brands[item?.brand_slug]?.logo?.url ||
                brands[item?.brand_slug]?.roundLogo?.url
              }
              blurhash={brands[item?.brand_slug]?.logo?.blurhash}
            />
            <h1 className="text-2xl font-lfg-book">{item.name}</h1>
          </div>
          <p className="text-lg text-gray-500 font-lfg-book line-clamp-3">
            {item.description}
          </p>
          {tags.length > 0 ? (
            <div className="flex space-x-1 items-center justify-start">
              {tags.map((tag: Tag) => {
                return DIETARY_ELEMENTS[tag.slug];
              })}
              <button
                className="relative cursor-pointer focus:outline-none"
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
              >
                <InformationCircleIcon className="text-lfg-secondary h-5 w-5" />
                <div
                  className={`${
                    showTooltip ? "block" : "hidden"
                  } text-center z-50 absolute bottom-8 -left-24 -right-24 shadow-md px-4 p-2 rounded-md border bg-white`}
                >
                  Depending on your chosen modifications, this item may be made{" "}
                  {getTagsDescriptor(tags)}
                </div>
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex justify-between items-end">
          <div className="text-xl">{usdFormatter.format(item.price / 100)}</div>
          <QuantitySelector
            quantity={quantity}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
          />
        </div>
      </div>
    </div>
  );
};

type QuantitySelectorProps = {
  quantity: number;
  onDecrease: () => void;
  onIncrease: () => void;
};

export const QuantitySelector = ({
  quantity,
  onDecrease,
  onIncrease,
}: QuantitySelectorProps) => {
  return (
    <div className="flex items-center">
      <button onClick={onDecrease}>
        <MinusIcon
          className="h-10 w-10 bg-gray-200 rounded text-gray-700 p-1"
          aria-hidden="true"
        />
      </button>
      <div className="px-3 text-3xl font-lfg-semibold w-16 text-center">
        {quantity}
      </div>
      <button onClick={onIncrease}>
        <PlusIcon
          className="h-10 w-10 bg-gray-200 rounded text-gray-700 p-1"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};
