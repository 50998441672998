import React from "react";
import { Terminal } from "@stripe/terminal-js";

type ITerminalContext = {
  terminal?: Terminal;
  isTerminalConnected: boolean;
  isReaderConnected: boolean;
};

const TerminalContext = React.createContext<ITerminalContext>({
  isTerminalConnected: false,
  isReaderConnected: false,
});

export default TerminalContext;
