import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { useLoyaltyAccount } from "../../hooks/useLoyaltyAccount";

type ThankYouProps = {
  isManager: boolean;
  cart: any;
  onFinish: () => void;
};

export const ThankYou = ({ isManager, cart, onFinish }: ThankYouProps) => {
  const history = useHistory();
  const [isExistingUser, setIsExistingUser] = useState(false);
  const { data } = useLoyaltyAccount(cart.phone_number);

  useEffect(() => {
    if (data && data.loyalty_account) {
      setIsExistingUser(true);
    } else {
      setIsExistingUser(false);
    }
  }, [data]);
  const onDoneClicked = () => {
    onFinish();
  };

  const firstName = cart.name.split(" ")[0];

  const onEmailReceiptClicked = () => {
    history.push("/email");
  };

  return (
    <div className="relative bg-lfg-light-gray h-screen flex flex-row items-center justify-center px-16">
      <main className="mx-auto max-w-7xl px-4">
        <div className="z-50 text-center">
          <h1 className="text-6xl tracking-tight flex flex-col items-center justify-center">
            <span className="block font-lfg-bold">Thanks {firstName}! 😁</span>
            <div className="max-w-md flex items-center justify-center">
              <span className="text-2xl block mt-8 text-gray-500">
                We'll text you when your food is ready. You can also track it on
                the status board!
              </span>
            </div>
          </h1>

          <div className="mt-8 max-w-md mx-auto flex justify-center">
            <div className="mt-3 rounded-md shadow sm:mt-0">
              <button
                onClick={onDoneClicked}
                className="text-2xl w-full flex items-center justify-center px-16 py-4 border border-transparent font-lfg-book rounded-md text-lfg-light-gray bg-lfg-primary hover:bg-opacity-80"
              >
                Done
              </button>
            </div>
          </div>
          {!isManager && (
            <div className="mt-8">
              <button
                className="text-2xl underline hover:text-opacity-80 text-gray-500"
                onClick={onEmailReceiptClicked}
              >
                Email my receipt
              </button>
            </div>
          )}

          {!isManager && (
            <div className="flex flex-col mt-8 max-w-xl mx-auto flex justify-center items-center space-y-4">
              <span className="text-2xl">
                {isExistingUser
                  ? `Redeem your ${Math.round(
                      data.loyalty_account.points / 10,
                    )} rewards points with the`
                  : `Sign up and claim your ${Math.round(
                      cart.subtotal / 10,
                    )} points with the`}
                <br />
                <span className="text-3xl font-lfg-bold">
                  Local Kitchens iOS app!
                </span>
              </span>
              <QRCode
                size={180}
                value={`https://localkitchens.com/app?utm_source=qr_code&utm_medium=${cart.location}_kiosk&utm_campaign=in_store_conversion`}
              />
              <span className="max-w-sm block mt-4 text-gray-500">
                Scan the code with your camera app to view the Local Kitchens
                app in the App Store
              </span>
            </div>
          )}
        </div>
      </main>
      <div className="w-1/3">
        <img
          alt="App Preview"
          src={"./assets/app_preview.png"}
          className="block w-3/4 rounded-lg object-cover shadow-lg"
        />
      </div>
    </div>
  );
};
