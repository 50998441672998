import { useQuery, gql } from "@apollo/client";

export const KIOSK_PROVIDERS_QUERY = gql`
  query KIOSK_PROVIDERS_QUERY {
    providers {
      id
      name
      slug
      revenue_center
    }
  }
`;

export const useKioskProviders = () => {
  const { data, error, loading, refetch } = useQuery(KIOSK_PROVIDERS_QUERY);
  return { data, error, loading, refetch };
};
