import { CheckCircleIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { Carousel } from "../../components/Carousel";
import { CarouselItem } from "../../components/CarouselItem";
import { useToasts } from "../../hooks/useToasts";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart, Menu, MenuItem } from "../../types";
import { useTagMenuItems } from "../ActiveCart/hooks/useTagMenuItems";

type CheckoutCarouselProps = {
  menus: Menu[];
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

export const CheckoutCarousel = ({
  menus,
  cart,
  onCartChange,
}: CheckoutCarouselProps) => {
  const { toasts, addToast } = useToasts(2000);

  const onAddItem = (message: string) => {
    addToast(message);
  };
  const dessertMenuItems: MenuItem[] = useTagMenuItems(
    "desserts",
    cart?.location || "",
  );
  const beverageMenuItems: MenuItem[] = useTagMenuItems(
    "drinks",
    cart?.location || "",
  );
  const { track } = useTrackWithFlags();

  useEffect(() => {
    track({
      event: "Viewed Checkout Carousel",
      properties: { cart_id: cart?.id },
    });
  }, [cart?.id]);

  const cartItemIds = new Set(cart.line_items.map((item) => item.id));

  const filteredBeverages = [...beverageMenuItems]
    .filter(
      (menuItem) =>
        menuItem.is_active &&
        !cartItemIds.has(menuItem.id) &&
        menus?.find((menu) => menu.brand.slug === menuItem.brand_slug)?.is_open,
    )
    .sort((menuItemA, menuItemB) =>
      menuItemA.count < menuItemB.count ? 1 : -1,
    )
    .map((menuItem, index) => (
      <CarouselItem
        cart={cart}
        itemIndex={index}
        key={`${menuItem.id}-${index}`}
        menuItem={menuItem}
        onCartChange={onCartChange}
        trackingProperty={"is_checkout_carousel_item" as keyof MenuItem}
        onAddItem={onAddItem}
      />
    ));

  const filteredDesserts = [...dessertMenuItems]
    .filter(
      (menuItem) =>
        menuItem.is_active &&
        !cartItemIds.has(menuItem.id) &&
        menus?.find((menu) => menu.brand.slug === menuItem.brand_slug)?.is_open,
    )
    .sort((menuItemA, menuItemB) =>
      menuItemA.count < menuItemB.count ? 1 : -1,
    )
    .map((menuItem, index) => (
      <CarouselItem
        cart={cart}
        itemIndex={index}
        key={`${menuItem.id}-${index}`}
        menuItem={menuItem}
        onCartChange={onCartChange}
        trackingProperty={"is_checkout_carousel_item" as keyof MenuItem}
        onAddItem={onAddItem}
      />
    ));

  const filteredItems = [...filteredBeverages, filteredDesserts];

  return filteredItems.length > 0 ? (
    <div className="w-full flex items-center flex-col">
      <div className="md:mt-12 mb-2 text-2xl text-gray-500 text-center">
        Add a Drink or Dessert
      </div>
      <Carousel className="max-w-5xl">{filteredItems}</Carousel>

      <div className="fixed right-8 top-8 flex flex-col gap-y-4">
        {toasts.map((message) => {
          return (
            <div className="bg-green-500 text-left w-64 rounded-md p-4 text-white font-lfg-semibold flex items-start">
              <CheckCircleIcon className="h-8 w-8 flex-shrink-0 mr-2 -mt-1" />
              <div>{message}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
