import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { useKioskProviders } from "../../hooks/useKioskProviders";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart } from "../../types";
import { usdFormatter } from "../../utils/usdFormatter";
import {
  getCartSubtotal,
  getTotalItemPrice,
} from "../ActiveCart/utils/accounting";
import { CateringProviders } from "./CateringProviders";

type ManagerProps = {
  cart: Cart;
  subtotal: number;
  total: number;
  tipAmount: number;
  taxAmount: number;
  serviceFeeAmount: number;
  serviceFeeRate: number;
  fulfillInPersonOrder: any;
};

export const Manager = ({
  cart,
  subtotal,
  total,
  tipAmount,
  taxAmount,
  serviceFeeAmount,
  serviceFeeRate,
  fulfillInPersonOrder,
}: ManagerProps) => {
  const { track } = useTrackWithFlags();
  const [isLoading, setIsLoading] = useState(false);
  const [isCatering, setIsCatering] = useState(false);
  const { data: kioskProvidersData, loading: kioskProvidersLoading } =
    useKioskProviders();
  const history = useHistory();

  if (!kioskProvidersData) return null;
  const cateringProviders = kioskProvidersData.providers.filter(
    (provider: any) => provider.revenue_center === "CATERING",
  );

  const onTrainingClick = () => {
    const subtotal = getCartSubtotal(cart);
    if (subtotal >= 4000) {
      if (
        !window.confirm(
          `Are you SURE this order is a TRAINING meal? The subtotal is ${usdFormatter.format(
            subtotal / 100,
          )}.`,
        )
      ) {
        return;
      }
    }
    fulfillOrder({ ...cart, type: "TRAINING" });
  };

  const onEmployeeClick = () => {
    const subtotal = getCartSubtotal(cart);
    if (subtotal >= 4000) {
      if (
        !window.confirm(
          `Are you SURE this order is an EMPLOYEE meal? The subtotal is ${usdFormatter.format(
            subtotal / 100,
          )}.`,
        )
      ) {
        return;
      }
    }
    fulfillOrder({ ...cart, type: "EMPLOYEE" });
  };

  const onCompensationClick = () => {
    const subtotal = getCartSubtotal(cart);
    if (subtotal >= 4000) {
      if (
        !window.confirm(
          `Are you SURE this order is a COMPENSATION meal? The subtotal is ${usdFormatter.format(
            subtotal / 100,
          )}.`,
        )
      ) {
        return;
      }
    }
    fulfillOrder({ ...cart, type: "COMPENSATION" });
  };

  const onMarketingClick = () => {
    const subtotal = getCartSubtotal(cart);
    if (subtotal >= 4000) {
      if (
        !window.confirm(
          `Are you SURE this order is a MARKETING meal? The subtotal is ${usdFormatter.format(
            subtotal / 100,
          )}.`,
        )
      ) {
        return;
      }
    }
    fulfillOrder({ ...cart, type: "MARKETING" });
  };

  const onCateringClick = () => {
    setIsCatering(!isCatering);
  };

  const fulfillOrder = async (cart: any) => {
    track({
      event: "Place Manager Order Attempted",
      properties: {
        cart,
      },
    });

    const updatedCart = {
      ...cart,
      subtotal: subtotal,
      points_earned: subtotal,
      tip_amount: tipAmount,
      tax_amount: taxAmount,
      service_fee_amount: serviceFeeAmount,
      service_fee_rate: serviceFeeRate,
      total: total,
      stripe_payment_intent_id: "",
    };

    setIsLoading(true);
    const res = await fulfillInPersonOrder({
      variables: { cart: JSON.stringify(updatedCart) },
    });

    if (res?.data?.fulfill_in_person_order?.status !== "200") {
      Sentry.captureException(
        `Failed to fulfill manager order for cart ${cart?.id} with status: ${res?.data?.fulfill_in_person_order?.status}`,
      );
    }

    setIsLoading(false);

    track({
      event: "Manager Order Completed",
      properties: {
        cart,
      },
    });

    history.push("/thank-you");
  };

  return (
    <div className="bg-gray-50 h-screen flex items-start w-screen">
      <main className="mx-auto w-5/12 px-4 mb-16 flex flex-col">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="flex-1 flex flex-col" style={{ minHeight: 314 }}>
          <div className="flex items-center justify-center">
            <h1 className="text-4xl tracking-tight font-extrabold">
              Place Order
            </h1>
          </div>
          <div className="mt-8 p-4 bg-white border border-gray-200">
            <ul className="flex-1 mb-8">
              {cart.line_items.map((item) => (
                <div className="flex justify-between text-xl mb-2">
                  <p className="font-lfg-semibold">
                    {item.quantity} {item.name}
                  </p>
                  <p>{usdFormatter.format(getTotalItemPrice(item) / 100)}</p>
                </div>
              ))}
            </ul>
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Subtotal</span>
              <span>{usdFormatter.format(subtotal / 100)}</span>
            </div>
            {serviceFeeAmount > 0 && (
              <div className="flex justify-between text-xl mb-2">
                <span className="font-lfg-semibold">
                  Service Fee
                  {serviceFeeAmount ? ` (${serviceFeeRate / 100}%)` : ""}
                </span>
                <span>{usdFormatter.format(serviceFeeAmount / 100)}</span>
              </div>
            )}
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Tax</span>
              <span>{usdFormatter.format(taxAmount / 100)}</span>
            </div>
            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Tip</span>
              <span>{usdFormatter.format(tipAmount / 100)}</span>
            </div>

            <div className="flex justify-between text-xl mb-2">
              <span className="font-lfg-semibold">Total</span>
              <span>{usdFormatter.format(total / 100)}</span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div>Processing order</div>
        ) : isCatering ? (
          <CateringProviders
            providers={cateringProviders}
            cart={cart}
            setIsCatering={setIsCatering}
            fulfillOrder={fulfillOrder}
          />
        ) : (
          <>
            <div className="mt-8">
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-blue-500"
                  onClick={onTrainingClick}
                >
                  Training
                </button>
              </div>
            </div>
            <div className="mt-8">
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-green-900"
                  onClick={onMarketingClick}
                >
                  Marketing
                </button>
              </div>
            </div>
            <div className="mt-8">
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-pink-900"
                  onClick={onEmployeeClick}
                >
                  Employee Meal
                </button>
              </div>
            </div>
            <div className="mt-8">
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-yellow-900"
                  onClick={onCompensationClick}
                >
                  Compensation
                </button>
              </div>
            </div>
            <div className="mt-8">
              <div className="rounded-md shadow">
                <button
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-green-900"
                  onClick={onCateringClick}
                >
                  Catering
                </button>
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};
