import { useMutation, gql } from "@apollo/client";

const KIOSK_CAPTURE_PAYMENT_INTENT = gql`
  mutation KioskCapturePaymentIntent($payment_intent_id: String) {
    capture_payment_intent(payment_intent_id: $payment_intent_id) {
      intent
      secret
    }
  }
`;

export const useCapturePaymentIntent = () => {
  const [capturePaymentIntent, { loading, error }] = useMutation(
    KIOSK_CAPTURE_PAYMENT_INTENT,
  );

  return { capturePaymentIntent, loading, error };
};
