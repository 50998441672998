import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Cart } from "../../types";
import { BackButton } from "../../components/BackButton";

type EmailProps = {
  cart: Cart;
  onFinishStep: (email: string) => void;
};

export const Email = ({ cart, onFinishStep }: EmailProps) => {
  const history = useHistory();
  const [email, setEmail] = useState(cart.email);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    onFinishStep(email);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen">
      <main className="mx-auto w-5/12 px-4 mb-16">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="text-center">
          <form onSubmit={onSubmit}>
            <h1 className="text-5xl tracking-tight font-lfg-bold">
              <span className="block xl:inline">Email</span>
            </h1>
            <p className="text-2xl text-gray-500 my-4">Get receipt emailed</p>
            <div className="mt-8">
              <input
                required
                autoFocus
                type="email"
                name="email"
                id="email"
                className="focus:outline-none focus:ring shadow-lg block w-full text-3xl border-gray-500 rounded-md h-20 p-4"
                placeholder="hi@example.com"
                onChange={onEmailChange}
                value={email}
                autoComplete="off"
              />
            </div>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button
                  type="submit"
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};
