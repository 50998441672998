import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  addMenuItemToCart,
  getEmptyCart,
  removeMenuItem,
  updateMenuItemInCart,
} from "../utils/cart";
import Routes from "./Routes";
import UserContext from "../context/UserContext";
import TerminalContext from "../context/TerminalContext";
import { Brands, Menu, ItemsMap, Cart } from "../types";

export const SWIPE_THRESHOLD = 0;

type CartProps = {
  locationSlug: string;
  taxRate: number;
  brands: Brands;
  menus: Menu[];
  itemsMap: ItemsMap;
};

const CartContainer = ({
  locationSlug,
  taxRate,
  brands,
  menus,
  itemsMap,
}: CartProps) => {
  const history = useHistory();
  // Cart is stored in memory
  const emptyCart = getEmptyCart(locationSlug);
  emptyCart.tax_rate = taxRate;
  const [cart, setCart] = useState(emptyCart);
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);

  // Updates the cart in memory
  const onCartChange = (cart: Cart) => setCart(cart);

  // Called at the end of the flow
  const onFinish = () => {
    clearCart();
    history.push("/");
  };

  const onMenuItemRemove = (externalId: string) => {
    const updatedCart = removeMenuItem(cart, externalId);
    onCartChange(updatedCart);
  };

  const onMenuItemEdit = (itemId: number, externalId: string) => {
    history.push(`/menu-item/${itemId}/edit/${externalId}`);
  };

  const onMenuItemAdd = (cart: Cart, externalId?: string) => {
    const updatedCart = addMenuItemToCart(cart, externalId);
    onCartChange(updatedCart);
    history.goBack();
  };

  const onMenuItemUpdate = (cart: Cart, externalId?: string) => {
    const updatedCart = updateMenuItemInCart(cart, externalId);
    onCartChange(updatedCart);
    history.goBack();
  };

  const setIsIdle = () => setIsIdleModalOpen(true);
  const setNotIdle = () => setIsIdleModalOpen(false);
  const clearCart = () => onCartChange(emptyCart);

  return (
    <UserContext.Consumer>
      {({ isManager, logout }) => (
        <TerminalContext.Consumer>
          {({ terminal }) => (
            <>
              {terminal && (
                <Routes
                  isManager={isManager}
                  logout={logout}
                  terminal={terminal}
                  setIsIdle={setIsIdle}
                  isIdleModalOpen={isIdleModalOpen}
                  cart={cart}
                  onCartChange={onCartChange}
                  onMenuItemEdit={onMenuItemEdit}
                  onMenuItemRemove={onMenuItemRemove}
                  onMenuItemAdd={onMenuItemAdd}
                  onMenuItemUpdate={onMenuItemUpdate}
                  clearCart={clearCart}
                  onFinish={onFinish}
                  setNotIdle={setNotIdle}
                  itemsMap={itemsMap}
                  brands={brands}
                  menus={menus}
                />
              )}
            </>
          )}
        </TerminalContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

export default CartContainer;
