import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import UserContext from "../context/UserContext";

type AppDisconnectedProps = {
  loginWithRedirect: any;
};

const AppDisconnected = ({ loginWithRedirect }: AppDisconnectedProps) => {
  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      Sentry.captureException("Kiosk: App Disconnected");
      loginWithRedirect();
    }, 5 * 1000);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, []);

  return (
    <div className="h-screen w-screen flex items-center justify-center flex-col">
      <h1 className="my-4">App disconnected, please use a different kiosk</h1>
      <button
        className="px-12 py-3 bg-green-800 text-white rounded"
        onClick={loginWithRedirect}
      >
        Login
      </button>
    </div>
  );
};

const AppDisconnectedContainer = () => (
  <UserContext.Consumer>
    {({ loginWithRedirect }) => (
      <AppDisconnected loginWithRedirect={loginWithRedirect} />
    )}
  </UserContext.Consumer>
);

export default AppDisconnectedContainer;
