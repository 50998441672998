import { getExtension } from "../utils/stringUtils";

export const CDN_URL = "https://cdn.localkitchens.co";
const SIZES = [320, 480, 640, 768, 1024, 1366, 1600];

export const slugify = (name?: string): string => {
  if (!name) return "";
  return name
    .toLowerCase()
    .split(" ")
    .join("-")
    .replaceAll("&", "and")
    .replaceAll("/", "")
    .replaceAll("'", "")
    .replaceAll(/\./gi, "");
};

type ResponsiveImageProps = {
  brandName?: string;
  itemName?: string;
  imageUrl?: string;
  blurhashUrl?: string;
  className?: string;
};

export const ResponsiveImage = ({
  brandName,
  itemName,
  imageUrl,
  className,
}: ResponsiveImageProps) => {
  const buildSrcSet = (
    brandName?: string,
    itemName?: string,
    imageUrl?: string,
  ) => {
    return SIZES.map((size) => {
      const width = `${size}w`;
      const extension = getExtension(imageUrl) || "jpg";
      const url = `${
        imageUrl
          ? imageUrl.split(`.${extension}`)[0]
          : `${CDN_URL}/${slugify(brandName)}/${slugify(itemName)}`
      }`;

      return `${url}-${width}.${extension} ${width}`;
    }).join(", ");
  };

  const defaultWidth = "640w";
  const defaultExtension = getExtension(imageUrl) || "jpg";
  const defaultSrc = `${
    imageUrl
      ? imageUrl.split(`.${defaultExtension}`)[0]
      : `${CDN_URL}/${slugify(brandName)}/${slugify(itemName)}`
  }-${defaultWidth}.${defaultExtension} ${defaultWidth}`;

  return brandName && itemName ? (
    <>
      <img
        style={{ objectFit: "cover" }}
        src={defaultSrc}
        srcSet={buildSrcSet(brandName, itemName, imageUrl)}
        className={`${className} "block"`}
      />
    </>
  ) : (
    <div />
  );
};
