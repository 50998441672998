import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Cart } from "../../types";
import { Menu } from "./Menu";

type MenuControllerProps = {
  cart: Cart;
  brands: any;
  menus: any;
  isManager: boolean;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  onCartChange: (cart: Cart) => void;
  clearCart: () => void;
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const MenuController = ({
  cart,
  brands,
  menus,
  isManager,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  onCartChange,
  clearCart,
}: MenuControllerProps) => {
  const queryParams = useQueryParams();
  const routeLocation = useLocation();
  const [brandIndex, setBrandIndex] = useState(0);

  const initialPageIndex = parseInt(queryParams.get("page") || "") || 0;
  const initialCollection = queryParams.get("collection") || "popular";

  // Load new menu when the route changes
  useEffect(() => {
    const path = routeLocation.pathname.split("/");
    const menuId = path.pop();
    if (menuId) {
      const newCurrentId = parseInt(menuId);
      const newBrandIndex = menus.findIndex(
        (menu: any) => menu.id === newCurrentId,
      );

      if (newBrandIndex === -1) {
        setBrandIndex(0);
      } else {
        setBrandIndex(newBrandIndex);
      }
    }
  }, [routeLocation]);

  if (!brands || !menus) {
    return null;
  }

  return (
    <Menu
      isManager={isManager}
      cart={cart}
      brands={brands}
      menus={menus}
      brandIndex={brandIndex}
      initialPageIndex={initialPageIndex}
      initialCollection={initialCollection}
      onMenuItemRemove={onMenuItemRemove}
      onMenuItemEdit={onMenuItemEdit}
      onMenuItemAdd={onMenuItemAdd}
      onMenuItemUpdate={onMenuItemUpdate}
      onCartChange={onCartChange}
      clearCart={clearCart}
    />
  );
};
