import { paginateItems } from "../../../utils/paginateItems";

const getMenuItems = (currentMenu: any, currentCollection?: any) => {
  if (currentMenu.brand.slug === "popular") {
    const collectionItems = currentMenu.collections.find(
      (collection: any) => collection.slug === currentCollection,
    );

    return collectionItems?.collection_items ?? currentMenu.most_popular;
  }

  const currentItems: any = [];
  const currentMenuCategories = currentMenu?.menus[0]?.menu_categories || [];
  const sortedMenuCategories = [...currentMenuCategories].sort(
    (a: any, b: any) => a.ordinal - b.ordinal,
  );
  sortedMenuCategories.forEach((menuCategory: any) => {
    menuCategory?.menu_items.forEach((menuItem: any) => {
      currentItems.push(menuItem);
    });
  });

  return currentItems;
};

export const getMenuItemPages = (
  currentMenu: any,
  itemsPerPage: number,
  currentCollection?: any,
) => {
  const menuItems = getMenuItems(currentMenu, currentCollection);
  const menuItemPages = paginateItems(menuItems, itemsPerPage, true);

  return menuItemPages;
};
