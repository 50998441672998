import { getExtension } from "../utils/stringUtils";

const SIZES = [320, 480, 640, 768, 1024, 1366, 1600];

type ResponsiveImageProps = {
  contentfulUrl?: string;
  className?: string;
  blurhash?: string;
};

export const ResponsiveContentfulImage = ({
  contentfulUrl,
  blurhash,
  className,
}: ResponsiveImageProps) => {
  const buildSrcSet = SIZES.map((size) => {
    const width = `${size}w`;
    const extension = getExtension(contentfulUrl) || "jpg";

    const url = blurhash
      ? `${
          contentfulUrl?.split(`.${extension}`)[0]
        }-${width}.${extension} ${width}`
      : `${contentfulUrl}?fm=jpg&fl=progressive&w=${size} ${size}w`;

    return `${url}`;
  }).join(", ");

  const defaultWidth = 640;
  const defaultExtension = getExtension(contentfulUrl) || "jpg";
  const defaultSrc = blurhash
    ? `${
        contentfulUrl?.split(`.${defaultExtension}`)[0]
      }-${defaultWidth}w.${defaultExtension}`
    : `${contentfulUrl}?fm=jpg&fl=progressive&w=${defaultWidth} ${defaultWidth}w`;

  return (
    <>
      <img
        style={{ objectFit: "cover" }}
        src={defaultSrc}
        alt={"Responsive Contentful Image"}
        srcSet={buildSrcSet}
        className={`${className} block z-20`}
      />
    </>
  );
};
