import React, { useMemo } from "react";
import LocationChooser from "./LocationChooser";
import { LocationAccount } from "../../types";
import { useUpdateLocationAccount } from "./hooks/useUpdateLocationAccount";
import { useLocations } from "./hooks/useLocations";

interface LocationChooserControllerProps {
  isManager: boolean;
  locationAccount?: LocationAccount;
}

const LocationChooserController = ({
  isManager,
  locationAccount,
}: LocationChooserControllerProps) => {
  const { data: locationsData, loading: locationsLoading } = useLocations();
  const { updateLocationAccountMutation, loading } = useUpdateLocationAccount();

  const updateLocationAccount = async (location_id: number) => {
    if (locationAccount) {
      await updateLocationAccountMutation({
        variables: {
          location_account_id: locationAccount?.id,
          email: locationAccount?.email,
          location_id,
        },
      });
    }
  };

  const isLoading = useMemo(() => {
    return loading || locationsLoading;
  }, [loading, locationsLoading]);

  if (!isManager || !locationAccount) {
    return (
      <div className="flex w-screen h-screen items-center justify-center">
        Manager access required and
        <span className="bg-red-50 rounded text-red-500 p-2">
          location_account
        </span>{" "}
        needed
      </div>
    );
  }

  return (
    <LocationChooser
      locations={locationsData}
      updateLocationAccount={updateLocationAccount}
      loading={isLoading}
    />
  );
};

export default LocationChooserController;
