import { useState } from "react";

export const useToasts = (delay: number) => {
  const [toasts, setToasts] = useState<string[]>([]);

  const addToast = (message: string) => {
    setToasts((prev) => [message, ...prev]);

    setTimeout(() => {
      setToasts((prev) => {
        const next = [...prev];
        next.pop();
        return next;
      });
    }, delay);
  };

  return { addToast, toasts };
};
