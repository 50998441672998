import { getCurrentDatetime } from "../utils/getPublicMenus";
import UserContext from "../context/UserContext";
import LocationContext from "../context/LocationContext";
import TerminalContext from "../context/TerminalContext";

type DebugStatementProps = {
  label: string;
  message: string;
};

const DebugStatement = ({ label, message }: DebugStatementProps) => (
  <p className="py-2 font-light text-sm">
    {label}
    <span className="block font-bold text-lg py-2">{message}</span>
  </p>
);

type DebugProps = {
  locationSlug: string;
  isManager: boolean;
  userEmail?: string;
  isReaderConnected: boolean;
  isTerminalConnected: boolean;
};

const Debug = ({
  locationSlug,
  userEmail,
  isManager,
  isReaderConnected,
  isTerminalConnected,
}: DebugProps) => (
  <section className="flex items-center justify-center h-screen">
    <article className="text-center py-16 px-16 relative border-2">
      <a className="absolute top-2 left-2" href="/">{`< Back`}</a>
      <DebugStatement
        label="Current [ NODE_ENV ]:"
        message={`${process.env.NODE_ENV}`}
      />
      <DebugStatement label="Current Location:" message={locationSlug} />
      <DebugStatement
        label="The app is currently using the time:"
        message={getCurrentDatetime().toString()}
      />
      <DebugStatement
        label="The app is currently logged in as:"
        message={userEmail || "nobody"}
      />
      <DebugStatement
        label="Is kiosk in manager mode:"
        message={`${isManager}`}
      />
      <DebugStatement
        label="Is Terminal Connected?:"
        message={`${isTerminalConnected}`}
      />
      <DebugStatement
        label="Is Reader Connected?:"
        message={`${isReaderConnected}`}
      />
    </article>
  </section>
);

const DebugContainer = () => (
  <UserContext.Consumer>
    {({ isManager, userEmail }) => (
      <LocationContext.Consumer>
        {({ locationSlug }) => (
          <TerminalContext.Consumer>
            {({ isReaderConnected, isTerminalConnected }) => (
              <Debug
                locationSlug={locationSlug}
                userEmail={userEmail}
                isManager={isManager}
                isReaderConnected={isReaderConnected}
                isTerminalConnected={isTerminalConnected}
              />
            )}
          </TerminalContext.Consumer>
        )}
      </LocationContext.Consumer>
    )}
  </UserContext.Consumer>
);

export default DebugContainer;
