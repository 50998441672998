import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart } from "../../types";
import { updatePhoneNumber } from "../../utils/cart";
import { PrepTimeEstimate } from "../PrepTimeEstimate";

type PhoneNumberProps = {
  isManager: boolean;
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

const formatPhoneNumber = (phoneNumberValue: any) => {
  if (!phoneNumberValue) {
    return "";
  }

  const phoneNumber = phoneNumberValue.replace(/[^\d]/g, "");

  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const PhoneNumber = ({
  isManager,
  cart,
  onCartChange,
}: PhoneNumberProps) => {
  const cartPhoneNumber = cart?.phone_number ? cart?.phone_number : "";
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState(cartPhoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>(
    undefined,
  );
  const { track } = useTrackWithFlags();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    track({
      event: "Phone Number Step Completed",
      properties: { phoneNumber },
    });

    const phoneNumberDigits = phoneNumber.replace(/[^\d]/g, "");

    if (phoneNumberDigits.length === 10) {
      const updatedCart = updatePhoneNumber(
        cart,
        phoneNumber.replace(/[^\d]/g, ""),
      );
      onCartChange(updatedCart);
      history.push("/tip");
    } else {
      setPhoneNumberError("Please provide valid phone number");
    }
  };

  const onSkip = (event: React.FormEvent) => {
    event.preventDefault();

    // explicitly pass in null
    const updatedCart = updatePhoneNumber(cart, null);
    onCartChange(updatedCart);
    history.push("/tip");
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  return (
    <div className="relative bg-gray-50 h-screen flex items-start w-screen">
      <PrepTimeEstimate />
      <main className="mx-auto w-9/12 px-4 mb-16">
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="text-center">
          <form onSubmit={onSubmit}>
            <p className="text-3xl my-4">
              Enter your phone number to receive text updates on when your order
              is ready
            </p>
            <span className="block mt-8 text-gray-500">
              Note: If you do not provide a valid phone number, you will not
              receive SMS updates on your order status
            </span>
            {phoneNumberError && (
              <p className="text-red-500">{phoneNumberError}</p>
            )}
            <div className="mt-6 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <input
                required
                autoFocus
                inputMode="numeric"
                type="text"
                name="phone-number"
                id="phone-number"
                className="focus:outline-none focus:ring shadow-lg block w-full text-3xl border-gray-500 rounded-md h-20 p-4"
                placeholder="(111) 111-1111"
                onChange={onPhoneNumberChange}
                value={phoneNumber}
                autoComplete="off"
              />
            </div>
            <div className="text-xl text-gray-500 my-2">
              <p>Have an account with us at localkitchens.com?</p>
              <p>You will earn loyalty points for this order.</p>
            </div>
            <div className="max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button
                  type="submit"
                  className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                >
                  Next
                </button>
              </div>
            </div>
            {isManager && (
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <button
                    onClick={onSkip}
                    className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-gray-700 bg-gray-300"
                  >
                    Skip
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};
