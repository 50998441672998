import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { SHOW_DINING_OPTION_LOCATIONS } from "../../config/showDiningOptionLocations";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart, DiningOption as DiningOptionEnum, Menu } from "../../types";
import { updateDiningOption } from "../../utils/cart";
import { CheckoutCarousel } from "../CheckoutCarousel/CheckoutCarousel";
import { PrepTimeEstimate } from "../PrepTimeEstimate";

type DiningOptionProps = {
  menus: Menu[];
  cart: Cart;
  onCartChange: (cart: Cart) => void;
};

export const DiningOption = ({
  menus,
  cart,
  onCartChange,
}: DiningOptionProps) => {
  const history = useHistory();
  const { track } = useTrackWithFlags();

  const generateSelectOption = (diningOption: DiningOptionEnum) => {
    return (event: React.FormEvent) => {
      event.preventDefault();

      track({
        event: "Dining Option step complete",
        properties: { diningOption },
      });

      const updatedCart = updateDiningOption(cart, diningOption);
      onCartChange(updatedCart);

      history.push("/name");
    };
  };

  return (
    <div className="fixed bg-gray-50 h-screen flex items-start w-screen flex-col">
      <main className="mx-auto w-5/12 px-4 mb-16">
        <PrepTimeEstimate />
        <div className="mb-4">
          <BackButton onBack={() => history.goBack()} />
        </div>
        <div className="text-center">
          <h1 className="text-5xl tracking-tight font-lfg-bold">
            <span>Choose your dining option</span>
          </h1>
          <p className="text-2xl text-gray-500 my-4">Dine In or Take Out</p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="w-full rounded-md shadow">
              <button
                type="button"
                className="w-full text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                onClick={generateSelectOption(DiningOptionEnum.DINE_IN)}
              >
                Dine In
              </button>
            </div>
          </div>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="w-full rounded-md shadow">
              <button
                type="button"
                className="w-full text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-lfg-book rounded-md text-white bg-lfg-primary"
                onClick={generateSelectOption(DiningOptionEnum.TAKE_OUT)}
              >
                Take Out
              </button>
            </div>
          </div>
        </div>
      </main>
      {SHOW_DINING_OPTION_LOCATIONS.includes(cart.location) ? (
        <div className="flex flex-col mx-auto w-full px-24 items-center">
          <CheckoutCarousel
            menus={menus}
            cart={cart}
            onCartChange={onCartChange}
          />
        </div>
      ) : null}
    </div>
  );
};
