import { useAuth } from "@localkitchens/passwordless-auth";
import { useEffect, useState } from "react";
import AppDisconnected from "../components/AppDisconnected";
import Loading from "../components/Loading";
import UserContext from "../context/UserContext";
import Location from "./Location";

const UserContainer = () => {
  // Each kiosk has its own account: kiosk+SERIALNUMBER@localfoodgroup.com
  const {
    user,
    isLoading,
    isAuthenticated,
    login,
    logout: logoutUser,
  } = useAuth();

  const userEmail = user?.email || "";

  // Managers can place test and employee orders
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (user) {
      const userRoles = user["https://app.localkitchens.co/roles"];
      if (userRoles.includes("KIOSK") && userRoles.includes("MANAGER")) {
        setIsManager(true);
      }
    }
  }, [user]);

  const returnTo = window.location.origin;

  const logout = () => logoutUser({ returnTo });

  if (isLoading) {
    return <Loading />;
  }

  if (!userEmail || !isAuthenticated) {
    return (
      <UserContext.Provider
        value={{ userEmail, logout, isManager, loginWithRedirect: login }}
      >
        <AppDisconnected />
      </UserContext.Provider>
    );
  }

  return (
    <UserContext.Provider
      value={{ userEmail, logout, isManager, loginWithRedirect: login }}
    >
      <Location userEmail={userEmail} />
    </UserContext.Provider>
  );
};

export default UserContainer;
