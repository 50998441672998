import * as Sentry from "@sentry/react";
import { loadStripeTerminal, Terminal as ITerminal } from "@stripe/terminal-js";
import React, { useEffect, useState } from "react";
import ErrorBanner from "../components/ErrorBanner";
import Loading from "../components/Loading";
import TerminalContext from "../context/TerminalContext";
import UserContext from "../context/UserContext";
import { useCreateConnectionToken } from "../hooks/useCreateConnectionToken";
import { fetchReader } from "../utils/stripe";
import Menu from "./Menu";

type TerminalProps = {
  userEmail: string;
  isManager: boolean;
  locationSlug: string;
};

const TerminalContainer = ({
  userEmail,
  isManager,
  locationSlug,
}: TerminalProps) => {
  const { createConnectionToken } = useCreateConnectionToken();
  const [terminal, setTerminal] = useState<ITerminal | undefined>(undefined);
  const [isTerminalConnected, setIsTerminalConnected] = useState(true);
  const [isReaderConnected, setIsReaderConnected] = useState(true);
  const isTerminalRequired = !isManager;

  const fetchConnectionToken = async (): Promise<string> => {
    const response = await createConnectionToken();
    return response.data.create_connection_token.secret;
  };

  const handleReaderDisconnect = (): void => {
    Sentry.captureException(
      `Kiosk: stripe unexpected reader disconnect for kiosk: ${userEmail}`,
    );
    setIsTerminalConnected(false);
    setIsReaderConnected(false);
  };

  const loadTerminal = async () => {
    const StripeTerminal = await loadStripeTerminal();

    if (!StripeTerminal) {
      setIsTerminalConnected(false);
      return Sentry.captureException(
        `Kiosk: loadStripeTerminal failed for kiosk: ${userEmail}`,
      );
    }

    const foundTerminal = await StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: handleReaderDisconnect,
    });

    if (!foundTerminal) {
      setIsTerminalConnected(false);
      return Sentry.captureException(
        `Kiosk: StripeTerminal.create failed for kiosk: ${userEmail}`,
      );
    }
    setTerminal(foundTerminal);
    setIsTerminalConnected(true);
  };

  const loadReader = async (currentTerminal: ITerminal, email: string) => {
    try {
      const reader = await fetchReader(currentTerminal, email);
      const connectionStatus = await currentTerminal.connectReader(reader);
      if ("error" in connectionStatus) {
        throw connectionStatus.error;
      }
      setIsReaderConnected(true);
    } catch (error) {
      setIsReaderConnected(false);
      return Sentry.captureException(`${error}: ${userEmail}`);
    }
  };

  useEffect(() => {
    loadTerminal();
  }, []);

  useEffect(() => {
    if (!isTerminalConnected) {
      setTimeout(loadTerminal, 30 * 1000);
    }
  }, [isTerminalConnected]);

  useEffect(() => {
    if (terminal && isTerminalRequired && userEmail) {
      loadReader(terminal, userEmail);
    }
  }, [terminal, isTerminalRequired, userEmail]);

  if (isTerminalRequired && !isTerminalConnected) {
    Sentry.captureException(
      `Kiosk: stripe unexpected terminal not connected for kiosk: ${userEmail}`,
    );
    return <ErrorBanner />;
  }

  if (isTerminalRequired && !isReaderConnected) {
    Sentry.captureException(
      `Kiosk: stripe reader not connected for kiosk: ${userEmail}.`,
    );
    return <ErrorBanner />;
  }

  if (!terminal) {
    return <Loading />;
  }

  return (
    <TerminalContext.Provider
      value={{ terminal, isTerminalConnected, isReaderConnected }}
    >
      <UserContext.Consumer>
        {({ isManager }) => (
          <Menu locationSlug={locationSlug} isManager={isManager} />
        )}
      </UserContext.Consumer>
    </TerminalContext.Provider>
  );
};

export default TerminalContainer;
