import { gql, useQuery } from "@apollo/client";

export const KIOSK_LOCATION_INFO_QUERY = gql`
  query KioskLocationInfoQuery($location_slug: String!) {
    store_location(location_slug: $location_slug) {
      id
      name
      stripe_location_id
      tax_rate
      recent_prep_time
    }
  }
`;

export const useLocationInfo = (locationSlug: string) => {
  const { data, error, loading, refetch } = useQuery(
    KIOSK_LOCATION_INFO_QUERY,
    {
      variables: { location_slug: locationSlug },
      fetchPolicy: "network-only",
      pollInterval: 5 * 60 * 1000,
    },
  );

  return { data, error, loading, refetch };
};
