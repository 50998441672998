import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { ReactNode, useCallback, useRef } from "react";

export const Carousel = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const scrollElement = useRef<HTMLDivElement | null>(null);
  const scrollUpsellItems = useCallback((amt: number) => {
    const el = scrollElement.current;
    if (el && el.firstChild) {
      const scrollAmount = (el.firstChild as HTMLElement).offsetWidth + 16;
      const leftCoord = el.scrollLeft + (amt > 0 ? scrollAmount : amt);
      el.scroll({
        left: leftCoord - (leftCoord % scrollAmount),
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div className="relative py-1 w-full flex justify-center">
      <div
        className={`flex overflow-x-scroll gap-x-4 pt-1 pb-3 h-md:pb-1 ${className}`}
        ref={scrollElement}
      >
        {children}
      </div>

      <div className="hidden select-none bg-white rounded-full opacity-75 hover:opacity-80 md:block absolute top-1/3 transform -translate-y-1/3 left-5 -ml-4 cursor-pointer">
        <ChevronLeftIcon
          onClick={() => scrollUpsellItems(-1)}
          className="h-10 w-10 text-gray-500 hover:opacity-80"
        />
      </div>
      <div className="hidden select-none bg-white rounded-full opacity-75 hover:opacity-80 md:block absolute top-1/3 transform -translate-y-1/3 right-5 -mr-4 cursor-pointer">
        <ChevronRightIcon
          onClick={() => scrollUpsellItems(1)}
          className="h-10 w-10 text-gray-500 hover:opacity-80"
        />
      </div>
    </div>
  );
};
