import { BackButton } from "../../../components/BackButton";

type TopBarProps = {
  onBack: () => void;
};

export const TopBar = ({ onBack }: TopBarProps) => {
  return (
    <div className="flex items-center pl-4" style={{ height: 116 }}>
      <BackButton onBack={onBack} />
    </div>
  );
};
