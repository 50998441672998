import { useMutation, gql } from "@apollo/client";

export const KIOSK_CREATE_CONNECTION_TOKEN = gql`
  mutation KioskCreateConnectionToken($location_id: String) {
    create_connection_token(location_id: $location_id) {
      secret
    }
  }
`;

export const useCreateConnectionToken = () => {
  const [createConnectionToken, { loading, error }] = useMutation(
    KIOSK_CREATE_CONNECTION_TOKEN,
  );
  return { createConnectionToken, loading, error };
};
