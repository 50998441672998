import React from "react";

type IUserContext = {
  isManager: boolean;
  userEmail: string;
  loginWithRedirect: () => void;
  logout: () => void;
};

const UserContext = React.createContext<IUserContext>({
  isManager: false,
  userEmail: "",
  loginWithRedirect: () => {},
  logout: () => {},
});

export default UserContext;
