import { useContext, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import QRCode from "react-qr-code";
import { Route, Switch, useHistory } from "react-router-dom";
import { Banner as BannerComponent } from "../../components/Banner";
import Terminal from "../../containers/Terminal";
import UserContext from "../../context/UserContext";
import { useBanners } from "../../hooks/useBanners";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";

type HomeProps = {
  locationSlug: string;
};

const SKIP_LOCATIONS: string[] = [];

export const Home = ({ locationSlug }: HomeProps) => {
  const history = useHistory();
  const { isManager } = useContext(UserContext);
  const { track } = useTrackWithFlags();
  const { data: bannersData } = useBanners(locationSlug);

  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    undefined,
  );

  const banner = useMemo(() => {
    if (bannersData?.kiosk_active_banners?.length) {
      return bannersData?.kiosk_active_banners[0];
    }
    return null;
  }, [bannersData]);

  const onStart = () => {
    // ensure the anonymous ID resets
    analytics.reset();

    track({
      event: "Clicked Start",
      properties: {
        location: locationSlug,
        isManager,
      },
    });
    history.push("/menu");
  };

  // If no usage on home screen for 3 hours do a hard refresh
  // Helps with connection issues that arise after long time of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 3,
    onIdle: window.location.reload,
    debounce: 500,
  });

  useEffect(() => {
    const setVersion = async () => {
      const res = await fetch("/version.txt");
      const version = await res.text();
      setCurrentVersion(version);
    };
    setVersion();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (currentVersion) {
      interval = setInterval(async () => {
        const pathname = window.location.pathname;
        const res = await fetch("/version.txt");
        const newVersion = await res.text();

        if (currentVersion !== newVersion && pathname === "/") {
          (window as any).location.reload();
        }
      }, 60 * 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentVersion]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <div className="h-screen flex flex-col">
            {banner && (
              <BannerComponent
                notice={banner.desktop}
                shortNotice={banner.mobile}
                backgroundColor={banner.background_color}
                showIcon
                showLink
              />
            )}
            {!SKIP_LOCATIONS.includes(locationSlug) ? (
              <div
                className="relative bg-light-blue h-screen flex items-end"
                onClick={onStart}
              >
                <main className="mx-auto max-w-7xl px-4 mb-16 z-50">
                  <div className="absolute top-0 bottom-0 left-0 right-0 pt-16 h-32 flex justify-center items-center">
                    <div
                      className="w-1/3 h-full"
                      style={{
                        backgroundImage: `url("https://lfg-landing.s3.us-west-2.amazonaws.com/local-kitchens-logolockup-master.svg")`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </div>
                  <div className="absolute top-0 bottom-0 left-0 right-0 grid grid-cols-3 h-full">
                    <div className="col-span-2 h-full p-8 flex flex-col items-center justify-center">
                      <div
                        className="w-full h-5/6"
                        style={{
                          backgroundImage: `url("https://lfg-landing.s3.us-west-2.amazonaws.com/landing-mobile-app-splash.png")`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                    <div className="col-span-1 h-full w-full flex flex-col justify-center items-center px-8 mt-8">
                      <div
                        className="h-full w-full"
                        style={{
                          backgroundImage: `url("https://lfg-images-test.s3.us-west-2.amazonaws.com/kiosk-promotion.png")`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3">
                        <button className="shadow-lg text-xl bg-lfg-primary opacity-90 w-full flex items-center justify-center px-12 py-3 font-lfg-medium rounded-full text-white hover:bg-opacity-75">
                          START
                        </button>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            ) : (
              <div
                className="relative bg-light-blue h-screen flex items-end"
                onClick={onStart}
              >
                <main className="mx-auto max-w-7xl px-4 mb-16 z-50">
                  <div className="absolute top-0 bottom-0 left-0 right-0 pt-16 h-32 flex justify-center items-center">
                    <div
                      className="w-1/3 h-full"
                      style={{
                        backgroundImage: `url("https://lfg-landing.s3.us-west-2.amazonaws.com/local-kitchens-logolockup-master.svg")`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </div>
                  <div className="absolute top-0 bottom-0 left-0 right-0 grid grid-cols-3 h-full">
                    <div className="col-span-2 h-full p-8 flex flex-col items-center justify-center">
                      <div
                        className="w-full h-5/6"
                        style={{
                          backgroundImage: `url("https://lfg-landing.s3.us-west-2.amazonaws.com/landing-mobile-app-splash.png")`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                    <div className="col-span-1 h-full w-full flex flex-col justify-center items-center px-8 mt-8">
                      <div className="w-full flex flex-col items-center text-left mb-8 bg-black rounded-3xl p-8 text-white">
                        <div className="text-4xl mb-4">Have an iPhone?</div>
                        <div className="text-2xl mb-4 text-center px-4">
                          Download the Local Kitchens mobile app to skip the
                          line, order delivery or pickup, and earn rewards!
                        </div>
                        <QRCode
                          size={200}
                          bgColor="#000"
                          fgColor="#FFF"
                          value={`https://localkitchens.com/app?utm_source=qr_code&utm_medium=${locationSlug}_kiosk&utm_campaign=kiosk_home_conversion`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3">
                        <button className="shadow-lg text-xl bg-lfg-primary opacity-90 w-full flex items-center justify-center px-12 py-3 font-lfg-medium rounded-full text-white hover:bg-opacity-75">
                          START
                        </button>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            )}
          </div>
        </Route>
      </Switch>

      <UserContext.Consumer>
        {({ userEmail, isManager }) => (
          <Terminal
            userEmail={userEmail}
            isManager={isManager}
            locationSlug={locationSlug}
          />
        )}
      </UserContext.Consumer>
    </>
  );
};
