import { Dialog } from "@headlessui/react";
import { Modal } from "../../../components/Modal";
import { LineItem } from "../../../types";
import { getModifierDescription } from "./ActiveLineItem";

type ActiveLineItemModalProps = {
  currentItem?: LineItem;
  isOpen: boolean;
  onClose: () => void;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
};

export const ActiveLineItemModal = ({
  currentItem,
  isOpen: open,
  onClose,
  onMenuItemRemove,
  onMenuItemEdit,
}: ActiveLineItemModalProps) => {
  const onRemoveFromCart = () => {
    onMenuItemRemove(currentItem?.external_id || "");
    onClose();
  };

  const onEdit = () => {
    onMenuItemEdit(currentItem?.id || 0, currentItem?.external_id || "");
    onClose();
  };

  const modifiersDescription = currentItem?.modifier_items
    .map((modifierItem) => getModifierDescription(modifierItem))
    .join(", ");

  return (
    <Modal open={open} onClose={onClose}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-4xl leading-6 font-lfg-book mb-8 mt-4"
          >
            {currentItem?.quantity} {currentItem?.name}
          </Dialog.Title>
          <div className="my-2 mb-8">
            <p className="text-3xl text-gray-500">{modifiersDescription}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="text-2xl py-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 bg-red-700 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
            onClick={onRemoveFromCart}
          >
            Remove from cart
          </button>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="text-2xl py-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 bg-gray-700 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto"
            onClick={onEdit}
          >
            Edit Item
          </button>
          <button
            type="button"
            className="text-2xl py-4 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 bg-white font-lfg-book text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
