import {
  useQuery,
  gql,
  OperationVariables,
  ApolloQueryResult,
} from "@apollo/client";
import { Location } from "../../../types";

export const KIOSK_LOCATIONS_QUERY = gql`
  query KIOSK_LOCATIONS_QUERY {
    locations {
      id
      name
      slug
      street
      state
      zipcode
      phone_number
      is_active
    }
  }
`;

interface useLocationProps {
  data: Location[];
  loading: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<any>>;
}

export const useLocations = (): useLocationProps => {
  const { data, loading, refetch } = useQuery(KIOSK_LOCATIONS_QUERY);
  return { data: data?.locations || [], loading, refetch };
};
