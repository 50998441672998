import { useIdleTimer } from "react-idle-timer";

type IdleTimerProps = {
  children: any;
  onIdle: any;
};

const DEFAULT_IDLE_SECONDS = 1 * 60;

export const IdleTimer = ({ children, onIdle }: IdleTimerProps) => {
  useIdleTimer({
    timeout: 1000 * DEFAULT_IDLE_SECONDS,
    debounce: 500,
    onIdle,
  });

  return <>{children}</>;
};
