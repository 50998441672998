import { ResponsiveContentfulImage } from "../../../components/ResponsiveContentfulImage";
import { Tappable } from "../../../components/Tappable";
import { useTrackWithFlags } from "../../../hooks/useTrackWithFlags";
import { useContext, useMemo } from "react";
import UserContext from "../../../context/UserContext";

type BrandPageProps = {
  menu: any;
  brands: any;
  index: any;
  currentBrandIndex: any;
  onBrandClick: any;
  collectionName: any;
  collectionLogo: any;
};

const BrandPage = ({
  menu,
  brands,
  index,
  currentBrandIndex,
  onBrandClick,
  collectionName,
  collectionLogo,
}: BrandPageProps) => {
  const { isManager } = useContext(UserContext);
  const { track } = useTrackWithFlags();

  const currentBrand = brands[menu?.brand?.slug];
  const hasRoundLogo = currentBrand?.roundLogo?.url !== undefined;

  const getLogo = () => {
    if (menu?.brand?.slug === "popular") {
      return collectionLogo;
    }

    if (hasRoundLogo) {
      return currentBrand?.roundLogo?.url;
    } else {
      return currentBrand?.logo?.url;
    }
  };

  const isOpen = useMemo(() => {
    return isManager || menu.is_open;
  }, [menu.is_open, menu.is_open]);

  return (
    <Tappable
      className="flex-1 mr-2"
      key={menu?.brand?.slug}
      onPress={() => {
        track({
          event: "Brand Selected",
          properties: {
            brand: menu?.brand?.name,
            storeId: menu?.id,
            location: menu?.location?.slug,
          },
        });
        onBrandClick(menu.id);
      }}
    >
      <button
        className={`${
          isOpen
            ? index === currentBrandIndex
              ? "border-4 border-green"
              : "border border-gray-200 opacity-60"
            : index === currentBrandIndex
            ? "border-4 border-red bg-red-100 "
            : "bg-red-100 border border-red-200 opacity-60"
        } flex flex-1 p-2 rounded bg-white items-center w-full focus:outline-none`}
      >
        {hasRoundLogo ? (
          <ResponsiveContentfulImage
            className="w-14 rounded-full"
            contentfulUrl={getLogo()}
            blurhash={currentBrand?.logo?.blurhash}
          />
        ) : (
          <div className="rounded-full bg-white h-14 w-14 flex justify-center items-center">
            <ResponsiveContentfulImage
              className="w-14 rounded-full"
              contentfulUrl={getLogo()}
              blurhash={currentBrand?.logo?.blurhash}
            />
          </div>
        )}
        <div
          className={`${
            index === currentBrandIndex ? "font-lfg-semibold" : "font-lfg-book"
          } ml-2 py-2 truncate`}
        >
          {menu?.brand?.slug === "popular" ? (
            <p className="text-left truncate">{collectionName}</p>
          ) : (
            <>
              <p className="text-left truncate">
                {menu?.brand?.name === "Local Kitchens"
                  ? "Drinks"
                  : menu?.brand?.name}
              </p>
              <p className="text-left text-sm truncate text-gray-500">
                {currentBrand?.cuisine}
              </p>
            </>
          )}
        </div>
      </button>
    </Tappable>
  );
};

export default BrandPage;
