import { useState } from "react";
import { Cart, Provider } from "../../types";
import { PickupDateTimePicker } from "./PickupDateTimePicker";

type CateringProvidersProps = {
  providers: Provider[];
  cart: Cart;
  setIsCatering: any;
  fulfillOrder: (cart: Cart) => void;
};

export const CateringProviders = ({
  providers,
  cart,
  setIsCatering,
  fulfillOrder,
}: CateringProvidersProps) => {
  const [isScheduling, setIsScheduling] = useState(false);
  const [providerSlug, setProviderSlug] = useState("");

  return !isScheduling ? (
    <>
      <div className="overflow-y-scroll" style={{ height: 300 }}>
        {providers.map((provider) => (
          <div className="mt-8" key={provider.slug}>
            <div className="rounded-md shadow">
              <button
                className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-purple-500"
                onClick={() => {
                  setIsScheduling(!isScheduling);
                  setProviderSlug(provider.slug);
                }}
              >
                {provider.name}
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <div className="rounded-md shadow">
          <button
            className="text-2xl py-5 px-28 w-full flex items-center justify-center border border-transparent font-medium rounded-md text-white bg-gray-900"
            onClick={() => setIsCatering()}
          >
            Back
          </button>
        </div>
      </div>
    </>
  ) : (
    <PickupDateTimePicker
      setIsScheduling={setIsScheduling}
      isScheduling={isScheduling}
      fulfillOrder={fulfillOrder}
      providerSlug={providerSlug}
      cart={cart}
    />
  );
};
