import React from "react";
type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen flex flex-col items-center justify-center">
          <span>Oops! Something went wrong and we'll have it fixed ASAP.</span>
          <span>
            In the meantime, please try another Kiosk or chat with support at
            1-415-992-8206
          </span>
        </div>
      );
    }

    return this.props.children;
  }
}
