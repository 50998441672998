import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ResponsiveImage } from "../components/ResponsiveImage";
import { useTrackWithFlags } from "../hooks/useTrackWithFlags";
import { Cart, MenuItem } from "../types";
import {
  addMenuItem,
  addMenuItemToCart,
  getCartActionProperties,
} from "../utils/cart";
import { usdFormatter } from "../utils/usdFormatter";

type CarouselItemProps = {
  cart: Cart;
  menuItem: MenuItem;
  itemIndex: number;
  onCartChange: (cart: Cart) => void;
  trackingProperty: keyof MenuItem;
  onAddItem: (message: string) => void;
};

export const CarouselItem = ({
  menuItem,
  cart,
  itemIndex,
  onCartChange,
  trackingProperty,
  onAddItem,
}: CarouselItemProps) => {
  const history = useHistory();
  const { track } = useTrackWithFlags();
  const savedTimeout = useRef<NodeJS.Timeout>();
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const shouldOpenFurtherSelection = useMemo(
    () =>
      menuItem.modifier_lists.length > 0 &&
      menuItem.modifier_lists.some((modifier) => modifier.min > 0),
    [menuItem],
  );

  const trackAction = useCallback(
    (event: string, properties: any = {}) => {
      track({
        event,
        properties: {
          product_id: menuItem.id,
          sku: menuItem.id,
          name: menuItem.name,
          price: menuItem.price / 100,
          brand: menuItem.brand_name,
          location: cart.location,
          index: itemIndex,
          cart_id: cart?.id,
          [trackingProperty]: true,
          ...properties,
        },
      });
    },
    [menuItem, cart],
  );

  const openMenuItem = (quantity?: number) => {
    if (savedTimeout.current) clearTimeout(savedTimeout.current);

    if (history.location.pathname.startsWith("/menu-item/")) {
      history.replace(
        `/menu-item/${menuItem.id}?${trackingProperty}=true${
          quantity ? `&quantity=${quantity}` : ""
        }`,
      );
    } else {
      history.push(
        `/menu-item/${menuItem.id}?${trackingProperty}=true${
          quantity ? `&quantity=${quantity}` : ""
        }`,
      );
    }
  };

  const handleAddItem = useCallback(
    async (item: MenuItem & { quantity: number }) => {
      if (shouldOpenFurtherSelection) {
        trackAction("Product Clicked");
        openMenuItem(item.quantity);
      } else {
        const addedMenuItem = addMenuItem(cart, {
          ...item,
          [trackingProperty]: true,
        });
        const updatedCart = addMenuItemToCart(
          addedMenuItem.updatedCart,
          addedMenuItem.externalId,
        );
        onCartChange(updatedCart);

        const cartActionProperties = getCartActionProperties(cart, item);
        trackAction("Product Added", {
          ...cartActionProperties,
          quantity: item.quantity,
        });

        onAddItem(
          `${item.quantity > 1 ? `${item.quantity}x ` : ""}${item.name} added!`,
        );
      }
    },
    [trackAction, cart, onAddItem],
  );

  useEffect(() => {
    if (savedTimeout.current) clearTimeout(savedTimeout.current);

    if (selectedQuantity === 0) {
      return;
    }

    savedTimeout.current = setTimeout(
      () => handleAddItem({ ...menuItem, quantity: selectedQuantity }),
      1200,
    );
  }, [selectedQuantity]);

  return (
    <div
      className={`flex items-center flex-col shrink-0 rounded w-56 ${
        menuItem.is_active ? "cursor-pointer" : "cursor-not-allowed"
      } p-0 border-none bg-transparent`}
      onClick={() => {
        setSelectedQuantity((prevQuantity) => prevQuantity + 1);
      }}
    >
      <div className="w-full relative hidden md:block mb-1">
        <ResponsiveImage
          blurhashUrl={menuItem?.blurhash}
          brandName={menuItem?.brand_name}
          className="h-36 w-full rounded-xl"
          imageUrl={menuItem?.image_url}
          itemName={menuItem?.name}
        />
        <QuantitySelectorButtons
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
        />
      </div>
      <div className="flex justify-between text-md gap-x-2 w-full">
        <div className="flex flex-row w-full">
          <div className="flex flex-col justify-start sm:justify-between w-48 md:flex-row mr-2">
            <div
              className="font-lfg-semibold line-clamp-2 sm:mr-2"
              style={{ maxWidth: 144 }}
            >
              {menuItem.name}
            </div>
            <div className="font-lfg-light">
              {usdFormatter.format(menuItem.price / 100)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type QuantitySelectorButtonsProps = {
  setSelectedQuantity: React.Dispatch<React.SetStateAction<number>>;
  selectedQuantity: number;
};

const QuantitySelectorButtons = ({
  setSelectedQuantity,
  selectedQuantity,
}: QuantitySelectorButtonsProps) => {
  return (
    <div
      className="absolute -bottom-1 -right-2 px-1 rounded-full bg-white border border-gray-50 shadow-md gap-x-2 h-12 flex items-center"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {selectedQuantity > 0 ? (
        <>
          <button
            className="font-lfg-semibold ml-1"
            onClick={() =>
              setSelectedQuantity((prevQuantity) =>
                Math.max(0, prevQuantity - 1),
              )
            }
          >
            <MinusIcon className="h-8 w-6" />
          </button>
          <span className="w-8 flex justify-center items-center">
            {selectedQuantity}
          </span>
        </>
      ) : null}
      <button
        className="font-lfg-semibold"
        onClick={() => setSelectedQuantity((prevQuantity) => prevQuantity + 1)}
      >
        <PlusIcon className="h-8 w-10" />
      </button>
    </div>
  );
};
