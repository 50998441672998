import { Dialog } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDoubleTap } from "use-double-tap";
import { Modal } from "../../components/Modal";
import { SHOW_DINING_OPTION_LOCATIONS } from "../../config/showDiningOptionLocations";
import LocationContext from "../../context/LocationContext";
import UserContext from "../../context/UserContext";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart, LineItem, Menu } from "../../types";
import {
  addMenuItem,
  getCartActionProperties,
  removeMenuItem,
  updateMenuItemQuantity,
} from "../../utils/cart";
import { usdFormatter } from "../../utils/usdFormatter";
import { QuantitySelector } from "../MenuItem/components/MenuItemDetail";
import { ActiveLineItemModal } from "./components/ActiveLineItemModal";
import { ActiveLineItems } from "./components/ActiveLineItems";
import { useUtensils } from "./hooks/useUtensils";
import { getCartSubtotal } from "./utils/accounting";

type ActiveCartProps = {
  menus?: Menu[];
  brands?: any[];
  externalId?: string;
  isRequiredStepsFinished?: boolean;
  viewType: "menu" | "menu-item" | "menu-item/edit";
  cart: Cart;
  collections?: any[];
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  onCartChange: (cart: Cart) => void;
  clearCart: () => void;
  logout: () => void;
};

const viewTypeMap = {
  menu: {
    buttonText: "Checkout",
    eventName: "Checkout Started",
  },
  "menu-item": {
    buttonText: "Add to cart",
    eventName: "Product Added",
  },
  "menu-item/edit": {
    buttonText: "Update cart",
    eventName: "Product Updated",
  },
};

export const ActiveCart = ({
  menus,
  brands,
  externalId,
  isRequiredStepsFinished,
  viewType,
  cart,
  collections,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  onCartChange,
  clearCart,
  logout,
}: ActiveCartProps) => {
  const history = useHistory();
  const { track } = useTrackWithFlags();
  const { recentPrepTime } = useContext(LocationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState<Array<any>>([]);
  const [currentItem, setCurrentItem] = useState<LineItem | undefined>(
    undefined,
  );
  const [isStartOverModalOpen, setIsStartOverModalOpen] = useState(false);
  const { isManager } = useContext(UserContext);
  useEffect(() => {
    setItems(cart.line_items);
  }, [cart]);

  const subTotal = getCartSubtotal(cart);

  const onItemClick = (item: LineItem) => {
    if (item.external_id === externalId) {
      return null;
    }

    setCurrentItem(item);
    setIsModalOpen(true);
  };

  const onActionClick = () => {
    if (eventName) {
      const item = cart.line_items.find(
        (item) => item.external_id === externalId,
      );
      const cartActionProperties = item
        ? getCartActionProperties(cart, item)
        : {};
      track({
        event: eventName,
        properties: {
          ...cartActionProperties,
          cart,
          prepTimeEstimate: recentPrepTime,
          isManager,
        },
      });
    }

    if (viewType === "menu") {
      if (SHOW_DINING_OPTION_LOCATIONS.includes(cart.location)) {
        history.push("/dining-option");
      } else {
        history.push("/name");
      }

      return null;
    }

    if (viewType === "menu-item") {
      onMenuItemAdd(cart, externalId);

      return null;
    }

    if (viewType === "menu-item/edit") {
      onMenuItemUpdate(cart, externalId);

      return null;
    }
  };

  const [utensilExternalId, setUtensilExternalId] = useState("");
  const [utensilQuantity, setUtensilQuantity] = useState(0);
  const utensils = useUtensils(cart?.location || "");

  const actionText = viewTypeMap[viewType].buttonText;
  const eventName = viewTypeMap[viewType].eventName;

  const onDecrease = () => {
    const newQuantity = utensilQuantity - 1;
    if (newQuantity === 0) {
      const updatedCart = removeMenuItem(cart, utensilExternalId);
      onCartChange(updatedCart);

      setUtensilQuantity(newQuantity);
      return null;
    }

    if (newQuantity > -1) {
      const updatedCart = updateMenuItemQuantity(
        cart,
        utensilExternalId,
        newQuantity,
      );
      onCartChange(updatedCart);

      setUtensilQuantity(newQuantity);
    }
  };

  const onIncrease = () => {
    const newQuantity = utensilQuantity + 1;
    if (utensilQuantity === 0) {
      const { externalId, updatedCart } = addMenuItem(cart, utensils[0]);
      onCartChange(updatedCart);

      setUtensilExternalId(externalId);
      setUtensilQuantity(1);
      return null;
    }

    if (newQuantity < 11) {
      const updatedCart = updateMenuItemQuantity(
        cart,
        utensilExternalId,
        newQuantity,
      );
      onCartChange(updatedCart);

      setUtensilQuantity(newQuantity);
    }
  };

  const onLogout = useDoubleTap(logout);

  return (
    <div
      className="bg-white border-t border-l border-r border-gray-200 w-3/12 flex flex-col rounded mr-4 mb-4"
      style={{ width: 380 }}
    >
      {cart.line_items.length === 0 ? (
        <div className="bg-gray-200 h-full flex justify-center items-center border-b border-gray-200">
          <span className="text-xl mb-28">
            No items have been added <button {...onLogout}>yet</button>
          </span>
        </div>
      ) : (
        <>
          <ActiveLineItems
            items={items}
            externalId={externalId}
            onItemClick={onItemClick}
          />
          {viewType === "menu" && (
            <div className="flex items-center justify-between px-4">
              <button
                className="text-lg text-gray-500 py-2 pr-2"
                onClick={() => setIsStartOverModalOpen(true)}
              >
                Start over
              </button>
              <div className="flex items-center">
                <div className="mr-3 text-lg font-lfg-semibold">Utensils</div>
                <QuantitySelector
                  quantity={utensilQuantity}
                  onDecrease={onDecrease}
                  onIncrease={onIncrease}
                />
              </div>
            </div>
          )}
          <div className="text-3xl flex justify-between mb-2 p-4 font-lfg-book">
            <p>Subtotal</p>
            <p>{usdFormatter.format(subTotal / 100)}</p>
          </div>
          {viewType === "menu" ? (
            <button
              onClick={onActionClick}
              className="w-full bg-lfg-primary block text-center py-6 text-white text-3xl font-lfg-book"
            >
              {actionText}
            </button>
          ) : (
            <button
              disabled={!isRequiredStepsFinished}
              onClick={onActionClick}
              className={`w-full ${
                isRequiredStepsFinished ? "bg-opacity-100" : "bg-opacity-20"
              } ${
                viewType === "menu-item" ? "bg-green-500" : "bg-lfg-primary"
              } block text-center py-6 text-white text-3xl font-lfg-book`}
            >
              {actionText}
            </button>
          )}
        </>
      )}
      <ActiveLineItemModal
        onMenuItemRemove={onMenuItemRemove}
        onMenuItemEdit={onMenuItemEdit}
        currentItem={currentItem}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Modal
        open={isStartOverModalOpen}
        onClose={() => setIsStartOverModalOpen(false)}
      >
        <Dialog.Title
          as="h3"
          className="text-4xl leading-6 font-lfg-book mb-8 mt-4"
        >
          Start over?
        </Dialog.Title>
        <div className="mt-16 flex flex-end">
          <button
            type="button"
            className="text-2xl py-4 px-16 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-green-500 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto mr-4"
            onClick={() => {
              clearCart();
              setIsStartOverModalOpen(false);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="text-2xl py-4 px-16 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-gray-500 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto"
            onClick={() => setIsStartOverModalOpen(false)}
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};
