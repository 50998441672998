export type Cart = {
  id: string;
  location: string;
  type: string;
  provider: string;
  line_items: Array<LineItem>;
  name: string;
  dining_option: DiningOption;
  email: string;
  phone_number: string | null;
  tip_rate: number;
  tip_amount: number;
  tax_rate: number;
  tax_amount: number;
  subtotal: number;
  total: number;
  points_earned: number;
  stripe_payment_intent_id: string;
  stashed_line_item?: LineItem;
};

export enum DiningOption {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  TAKE_OUT = "TAKE_OUT",
}

export type LineItem = {
  id: number;
  external_id: string;
  name: string;
  description: string;
  brand_name: string;
  price: number;
  modifier_items: Array<ModifierItem>;
  quantity: number;
  is_added: boolean;
  is_checkout_carousel_item: boolean;
};

export type ModifierItem = {
  id: number;
  external_id: string;
  modifier_list_id: number;
  modifier_list_index: number;
  name: string;
  quantity: number;
  price: number;
};

export type Modifier = {
  id: number;
  name: string;
  is_single_select: boolean;
  max: number;
  min: number;
  modifier_items: ModifierItem[];
};

export type Provider = {
  id: number;
  name: string;
  slug: string;
  revenueCenter: string;
};

export type MenuItem = {
  id: number;
  brand_name: string;
  brand_slug: string;
  image_url?: string;
  blurhash?: string;
  count: number;
  description: string;
  is_active: boolean;
  is_open: boolean;
  modifier_lists: Modifier[];
  name: string;
  price: number;
  store_id: number;
  tags: string[];
};

export type Asset = {
  url: string;
  blurhash?: string;
};

export type Brand = {
  brandId: number;
  brandName: string;
  brandSlug: string;
  cuisine: string;
  foodHeroImage: Asset;
  logo: Asset;
  roundLogo: Asset;
};

export type Brands = {
  [key: string]: Brand;
};

export type MenuBrand = {
  name: string;
  slug: string;
};

export type Location = {
  id: number;
  name: string;
  slug: string;
  service_fee_rate: number;
  recent_prep_time?: number;
};

export type Menu = {
  id: number;
  is_open: boolean;
  brand: MenuBrand;
  location: Location;
  menus: any[];
  collections: any[];
  store_hours: any[];
  most_popular: MenuItem[];
};

type BannerBrand = {
  id: number;
  name: string;
  slug: string;
};

export type Banner = {
  id: number;
  desktop: string;
  mobile: string;
  background_color?: string;
  start_date?: string;
  end_date?: string;
  title?: string;
  platforms?: string[];
  brands?: BannerBrand[];
  locations?: Location[];
};

export type ItemsMap = {
  [key: number]: MenuItem;
};

export type Coupon = {
  id: number;
  name: string;
  display_name: string;
  description?: string;
  code: string;
  discount_rate?: number;
  max_amount?: number;
  credits?: number;
  brand_attrs?: any;
  is_active: Boolean;
  loyalty_account_attrs?: any;
  location_attrs?: any;
  inserted_at?: number;
  is_discoverable?: Boolean;
  minimum_subtotal?: number;
};

export type LocationAccount = {
  id: number;
  email: string;
  location: Location;
};
