import { useCallback } from "react";

export const useTrackWithFlags = () => {
  const track = useCallback(({ event, properties }) => {
    analytics.track(
      event,
      {
        ...(properties
          ? {
              ...properties,
              platform: "kiosk",
            }
          : {}),
      },
      { context: getSegmentContext(properties) },
    );
  }, []);

  const page = useCallback(({ name }) => {
    analytics.page(name);
  }, []);

  return { track, page };
};

/*
 * Traits spec: https://segment.com/docs/connections/spec/identify/#traits
 */
type SegmentUserTraits = {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
};

function getSegmentUserTraits(properties: any): SegmentUserTraits {
  let traits = {
    ...(properties?.phoneNumber && {
      phone: properties.phoneNumber,
    }),
    ...(properties?.email && { email: properties.email }),
  };
  if (properties?.cart) {
    traits = {
      ...traits,
      ...(properties?.cart?.phoneNumber && {
        phone: properties.cart.phoneNumber,
      }),
      ...(properties?.cart?.name && { name: properties.cart.name }),
      ...(properties?.cart?.email && { email: properties.cart.email }),
    };
  }
  return traits;
}

/**
 * Context spec: https://segment.com/docs/connections/spec/common/#context
 */
type SegmentContext = {
  traits: SegmentUserTraits;
};

/**
 * Returns the context object used in Segment track calls.
 * Currently only used for the trait property.
 *
 * Context docs: https://segment.com/docs/connections/spec/common/#context
 */
export function getSegmentContext(properties: any): SegmentContext {
  const traits = getSegmentUserTraits(properties);

  return {
    traits,
  };
}
