import { Terminal, Reader } from "@stripe/terminal-js";
import * as Sentry from "@sentry/react";
import { isDev } from "../constants";

const fetchReader = async (
  terminal: Terminal,
  readerLabel: string,
): Promise<Reader> => {
  const simulated = isDev;
  // terminal can be simulated in development
  const discoverResult = await terminal.discoverReaders({ simulated });

  if ("error" in discoverResult) {
    Sentry.captureException(discoverResult.error);
    throw discoverResult.error;
  }

  if (simulated) {
    return discoverResult.discoveredReaders[0];
  }

  // terminals are matched with the auth user email currently logged in.
  const reader = discoverResult.discoveredReaders.find(
    (rdr) => rdr.label === readerLabel,
  );

  if (!reader) {
    Sentry.captureException("fetch reader failed");
    throw new Error("fetch reader failed");
  }
  return reader;
};

export { fetchReader };
