import { Dialog } from "@headlessui/react";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "../../components/Modal";
import UserContext from "../../context/UserContext";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import {
  Cart,
  LineItem,
  MenuItem as IMenuItem,
  ModifierItem,
} from "../../types";
import {
  getCartActionProperties,
  removeModifierItem,
  updateMenuItemQuantity,
  updateModifierItemQuantity,
} from "../../utils/cart";
import { useIsSafariMobile } from "../../utils/isSafariMobile";
import { paginateItems } from "../../utils/paginateItems";
import { ActiveCart } from "../ActiveCart";
import { MenuItemDetail } from "./components/MenuItemDetail";
import {
  getModifierItemListInfo,
  ModifierItemSelector,
} from "./components/ModifierItemSelector";
import { TopBar } from "./components/TopBar";

type MenuItemProps = {
  externalId: string;
  cart: Cart;
  item: IMenuItem;
  brands: any;
  collections: any[];
  viewType: "menu" | "menu-item" | "menu-item/edit";
  onCartChange: (cart: Cart) => void;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  clearCart: () => void;
};

const getPosition = (stepIndex: number, currentIndex: number) => {
  if (stepIndex === currentIndex) {
    return "current";
  }
  if (stepIndex > currentIndex) {
    return "before";
  }
  return "after";
};

export const MODIFIER_ITEM_PER_PAGE = 4;

type ModifierListStep = {
  id: string;
  name: string;
  position: "before" | "current" | "after";
  isValid: boolean;
  isOptional: boolean;
};

export const MenuItem = ({
  brands,
  viewType,
  externalId,
  cart,
  collections,
  item,
  onCartChange,
  onMenuItemRemove,
  onMenuItemEdit,
  onMenuItemAdd,
  onMenuItemUpdate,
  clearCart,
}: MenuItemProps) => {
  const history = useHistory();
  const [modifierListIndex, setModifierListIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [modifierListSteps, setModifierListSteps] = useState<
    Array<ModifierListStep>
  >([]);
  const [isStartOverModalOpen, setIsStartOverModalOpen] = useState(false);
  // Only display "Are you sure?" modal if changes have been made to item
  const [itemSelectionsMade, setItemSelectionsMade] = useState(false);

  const sortedModifierLists = orderBy(item.modifier_lists, ["min"], ["desc"]);
  const { track } = useTrackWithFlags();

  useEffect(() => {
    const cartActionProperties = getCartActionProperties(cart, item);

    track({
      event: "Product Viewed",
      properties: { ...cartActionProperties },
    });
  }, []);

  useEffect(() => {
    const addedModifierItems = cart.line_items
      .filter((lineItem) => lineItem.external_id === externalId)
      .map((lineItem) => lineItem.modifier_items)
      .flat();

    const newModifierListSteps: Array<ModifierListStep> =
      sortedModifierLists.map((modifierList: any, index: number) => {
        const modifierItemsInList = addedModifierItems.filter((item) => {
          return item.modifier_list_id === modifierList.id;
        });

        const modifierItemsCount = modifierItemsInList.reduce(
          (prevVal: number, currVal: ModifierItem) =>
            (prevVal += currVal.quantity),
          0,
        );
        const { isOptional } = getModifierItemListInfo(modifierList);
        let isValid = false;

        if (modifierList.min === 0 && modifierList.max === 0) {
          // Choose as many as you like
          isValid = true;
        } else if (modifierList.min === 0 && modifierList.max > 0) {
          // Choose up to max
          isValid = modifierItemsCount <= modifierList.max;
        } else if (modifierList.min === modifierList.max) {
          // Choose min
          isValid = modifierItemsCount === modifierList.min;
        } else if (modifierList.max > modifierList.min) {
          // Choose min
          isValid =
            modifierItemsCount <= modifierList.max &&
            modifierItemsCount >= modifierList.min;
        } else {
          // Choose at least min
          isValid = modifierItemsCount >= modifierList.min;
        }

        const modifierListStep: ModifierListStep = {
          id: `${index + 1}`,
          name: modifierList.name,
          position: getPosition(modifierListIndex, index),
          isValid,
          isOptional,
        };

        return modifierListStep;
      });

    setModifierListSteps(newModifierListSteps);
  }, [cart, modifierListIndex]);

  const isSafariMobile = useIsSafariMobile();

  const currentModifierList = sortedModifierLists[modifierListIndex];
  const modifierItems = currentModifierList
    ? currentModifierList.modifier_items.map((modifierItem: any) => {
        return {
          ...modifierItem,
          quantity: 0,
        };
      })
    : [];

  const modifierItemPages = paginateItems(
    modifierItems,
    MODIFIER_ITEM_PER_PAGE,
  );

  const onStepPress = (stepIndex: number) => {
    if (stepIndex > modifierListIndex + 1) {
      return null;
    }

    setItemSelectionsMade(true);
    setCurrentPageIndex(0);
    setModifierListIndex(stepIndex);
  };

  const [currentLineItem] = cart.line_items.filter((lineItem: LineItem) => {
    return lineItem.external_id === externalId;
  });

  const onModifierItemClick = (
    modifierItem: ModifierItem,
    modifierListIndex: any,
  ) => {
    setItemSelectionsMade(true);

    const updatedCart = { ...cart };

    const [currentLineItem] = updatedCart.line_items.filter(
      (lineItem: LineItem) => {
        return lineItem.external_id === externalId;
      },
    );

    const [existingModifierItem] = currentLineItem.modifier_items.filter(
      (item: ModifierItem) => {
        return item.id === modifierItem.id;
      },
    );

    if (existingModifierItem) {
      currentLineItem.modifier_items = currentLineItem.modifier_items.filter(
        (item: ModifierItem) => {
          return item.id !== modifierItem.id;
        },
      );
    } else {
      const modifierItemsInList = currentLineItem.modifier_items.filter(
        (mod: any) => mod.modifier_list_id === currentModifierList.id,
      );

      if (
        currentModifierList.min !== 0 &&
        currentModifierList.min === currentModifierList.max &&
        modifierItemsInList.length === currentModifierList.min
      ) {
        return null;
      }

      if (
        currentModifierList.min === 0 &&
        currentModifierList.max > 0 &&
        modifierItemsInList.length === currentModifierList.max
      ) {
        return null;
      }

      currentLineItem.modifier_items.push({
        id: modifierItem.id,
        external_id: uuidv4(),
        modifier_list_id: currentModifierList.id,
        name: modifierItem.name,
        quantity: 1,
        modifier_list_index: modifierListIndex,
        price: modifierItem.price,
      });

      currentLineItem.modifier_items.sort(
        (a: any, b: any) => a.modifier_list_index - b.modifier_list_index,
      );
    }

    // Move to the next step if we are not at the last step and we reached max
    if (modifierListIndex !== item.modifier_lists.length - 1) {
      checkMoveToNext(updatedCart);
    }

    onCartChange(updatedCart);
  };

  const onChangeModifierItemQuantity = (
    modifierItemExternalId: string,
    quantity: number,
  ) => {
    setItemSelectionsMade(true);
    let updatedCart: Cart;
    if (quantity === 0) {
      updatedCart = removeModifierItem(
        cart,
        externalId,
        modifierItemExternalId,
      );
    } else {
      updatedCart = updateModifierItemQuantity(
        cart,
        modifierItemExternalId,
        quantity,
      );
    }

    // Move to the next step if we are not at the last step and we reached max
    if (modifierListIndex !== item.modifier_lists.length - 1) {
      checkMoveToNext(updatedCart);
    }

    onCartChange(updatedCart);
  };

  const checkMoveToNext = (updatedCart: Cart) => {
    const addedModifierItems = updatedCart.line_items
      .filter((lineItem) => lineItem.external_id === externalId)
      .map((lineItem) => lineItem.modifier_items)
      .flat()
      .filter(
        (modifierItem) =>
          modifierItem.modifier_list_id === currentModifierList.id,
      );

    const addedModifierItemsCount = addedModifierItems.reduce(
      (accum: any, val: any) => accum + val.quantity,
      0,
    );
    if (
      addedModifierItemsCount === currentModifierList.max &&
      addedModifierItemsCount !== 0
    ) {
      setModifierListIndex(modifierListIndex + 1);
    }
  };

  const onChangeMenuItemQuantity = (quantity: number) => {
    setItemSelectionsMade(true);
    const updatedCart = updateMenuItemQuantity(cart, externalId, quantity);
    onCartChange(updatedCart);
  };

  const onMenuItemRemoveAndGoBack = (menuItemExternalId: string) => {
    onMenuItemRemove(menuItemExternalId);
    if (menuItemExternalId === externalId) {
      history.goBack();
    }
  };

  const addedModifierItems = cart.line_items
    .filter((lineItem) => {
      return lineItem.external_id === externalId;
    })
    .map((lineItem) => {
      return lineItem.modifier_items;
    })
    .flat();

  const modifierItemsMap: any = {};

  addedModifierItems.forEach((addedModifierItem) => {
    modifierItemsMap[addedModifierItem.id] = {
      quantity: addedModifierItem.quantity,
      external_id: addedModifierItem.external_id,
    };
  });

  // Get number of required steps
  const requiredSteps = modifierListSteps.filter((step) => !step.isOptional);
  const validRequiredSteps = requiredSteps.filter((step) => step.isValid);
  const isRequiredStepsFinished =
    requiredSteps.length === validRequiredSteps.length;

  const onBack = () => {
    const updatedCart = { ...cart };

    for (let i = 0; i < updatedCart.line_items.length; i += 1) {
      const currentLineItem = updatedCart.line_items[i];

      if (
        currentLineItem.external_id === externalId &&
        updatedCart.stashed_line_item
      ) {
        updatedCart.line_items[i] = updatedCart.stashed_line_item;
        break;
      }
    }

    onCartChange(updatedCart);

    history.goBack();
  };

  return (
    <div
      className={`bg-lfg-light-gray h-screen w-full flex flex-col ${
        isSafariMobile ? "fixed" : ""
      }`}
    >
      <TopBar
        onBack={() => {
          if (itemSelectionsMade) {
            setIsStartOverModalOpen(true);
          } else {
            onBack();
          }
        }}
      />
      <div className="flex flex-1 ml-4">
        <div className="flex-1 flex flex-col mr-4 bg-white border border-gray-200 mb-4">
          <MenuItemDetail
            brands={brands}
            currentLineItem={currentLineItem}
            item={item}
            onChangeQuantity={onChangeMenuItemQuantity}
          />

          {currentModifierList && (
            <ModifierItemSelector
              modifierListIndex={modifierListIndex}
              modifierItemsMap={modifierItemsMap}
              currentPageIndex={currentPageIndex}
              setCurrentPageIndex={setCurrentPageIndex}
              modifierListSteps={modifierListSteps}
              onStepPress={onStepPress}
              currentModifierList={currentModifierList}
              modifierItemPages={modifierItemPages}
              onModifierItemClick={onModifierItemClick}
              onChangeModifierItemQuantity={onChangeModifierItemQuantity}
            />
          )}
        </div>

        <UserContext.Consumer>
          {({ logout }) => (
            <ActiveCart
              externalId={externalId}
              isRequiredStepsFinished={isRequiredStepsFinished}
              viewType={viewType}
              cart={cart}
              brands={brands}
              collections={collections}
              onMenuItemRemove={onMenuItemRemoveAndGoBack}
              onMenuItemEdit={onMenuItemEdit}
              onMenuItemAdd={onMenuItemAdd}
              onMenuItemUpdate={onMenuItemUpdate}
              onCartChange={onCartChange}
              clearCart={clearCart}
              logout={logout}
            />
          )}
        </UserContext.Consumer>
      </div>
      {isStartOverModalOpen && (
        <AreYouSureModal
          viewType={viewType}
          onConfirm={onBack}
          onClose={() => setIsStartOverModalOpen(false)}
        />
      )}
    </div>
  );
};

type AreYouSureModalProps = {
  viewType: any;
  onConfirm: any;
  onClose: any;
};

const AreYouSureModal = ({
  viewType,
  onConfirm,
  onClose,
}: AreYouSureModalProps) => {
  useIdleTimer({
    timeout: 1000 * 10,
    onIdle: () => onClose(),
    debounce: 500,
  });

  return (
    <Modal open={true} onClose={onClose}>
      <Dialog.Title
        as="h3"
        className="text-4xl leading-6 font-lfg-book mb-8 mt-4"
      >
        {viewType === "menu-item/edit"
          ? "Clear unsaved changes?"
          : "Clear item from cart?"}
      </Dialog.Title>
      <div className="mt-16 flex flex-end">
        <button
          type="button"
          className="text-2xl py-4 px-16 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-green-500 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:w-auto mr-4"
          onClick={onConfirm}
        >
          Yes
        </button>
        <button
          type="button"
          className="text-2xl py-4 px-16 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-gray-500 font-lfg-book text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto"
          onClick={onClose}
        >
          No
        </button>
      </div>
    </Modal>
  );
};
