import { useMutation, gql } from "@apollo/client";

const ATTACH_RECEIPT_EMAIL = gql`
  mutation AttachReceiptEmail(
    $payment_intent_id: String
    $receipt_email: String
  ) {
    attach_receipt_email(
      payment_intent_id: $payment_intent_id
      receipt_email: $receipt_email
    ) {
      id
    }
  }
`;

export const useAttachReceiptEmail = () => {
  const [attachReceiptEmail, { loading, error }] =
    useMutation(ATTACH_RECEIPT_EMAIL);

  return { attachReceiptEmail, loading, error };
};
